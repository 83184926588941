import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Container,
} from '@safelyq/bulma-ui-library';
import { MenuList } from './components';
import { useData } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessName, setBusinessCategory, setBusinessFeatures, setBusinessId, setIsAssosiate, setIsAdmin, setBusinessSubLevels, setBusinessSubscriptionPlan } from 'Redux/BusinessSlice/Business';

const StyledContainer = styled(Container).attrs(() => ({}))`
  padding: 12px;
`;

export const AdminDrawer = ({
  // isSuperAdmin,
  onLogout,
}) => {
  // export const AdminDrawer = ({ unreadMessages, toggle, onRefetch, isAssociate, isSuperAdmin, onLogout }) => {
  const dispatch = useDispatch();
  const businessCategoryFeaturesGlobal = useSelector((state) => state?.business?.businessCategory?.features);

  const toggle = () => {
    return false;
  }

  let { pathname } = useLocation();
  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  let businessId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const id = parseInt(businessId);

  useEffect(() => {
    id && dispatch(setBusinessId(id))
  }, [id])

  const { business, onLoadBusiness } = useData(id);
  useEffect(() => {
    onLoadBusiness({ variables: { id } });
  }, [])
  const categoryName = business?.businessCategory?.name;
  const googleAllowed = business?.businessCategory?.features?.googleReserveAllowed;
  const couponAllowed = business?.businessCategory?.features?.couponAllowed;
  const isAdmin = business?.businessPermissions?.isAdmin;
  const isAssociate = business?.businessPermissions?.isAssociate;
  const businessSubLevels = business?.businessSubLevels;
  const businessSubscriptionPlan = business?.businessSubscription?.subscriptionPlan?.name;

  /******* Dispatch Data to the global State *********/
  // Set Business Name to global state every time it changes
  useEffect(() => {
    if (business?.name || business?.businessCategory?.name) {
      business?.name && dispatch(setBusinessName(business?.name));
    }

  }, [business?.name])

  //  Set Business Category to global state every time it changes
  useEffect(() => {
    if (business?.businessCategory?.name && !businessCategoryFeaturesGlobal) {
      business?.businessCategory?.name && dispatch(setBusinessCategory(business?.businessCategory));
    }

  }, [categoryName, googleAllowed, couponAllowed])

  // Set Features allowed in the current subscription plan to global state if the state is empty
  useEffect(() => {
    if (business?.businessSubscription?.subscriptionPlan?.features) {
      dispatch(setBusinessFeatures(business?.businessSubscription?.subscriptionPlan?.features))
    }
  }, [business?.businessSubscription?.subscriptionPlan?.features])

  // Set business sub levels to the global state
  useEffect(() => {
    if (businessSubLevels) {
      dispatch(setBusinessSubLevels(businessSubLevels))
    }
  }, [businessSubLevels])

  // Set businessSubscriptionPlan to the global state
  useEffect(() => {
    if (businessSubscriptionPlan) {
      dispatch(setBusinessSubscriptionPlan(businessSubscriptionPlan))
    }
  }, [businessSubscriptionPlan])

  // Set isAssociate and isAdmin to the global state
  useEffect(() => {
    if (isAssociate !== null && isAssociate !== undefined) {
      dispatch(setIsAssosiate(isAssociate));
    }
    if (isAdmin !== null && isAdmin !== undefined) {
      dispatch(setIsAdmin(isAdmin));
    }
  }, [isAssociate, isAdmin])

  // const isError = isLoading || error || !name;

  return (
    <StyledContainer>
      <aside class='menu'>
        <MenuList
          businessId={id}
          onLogout={onLogout}
          toggle={toggle}
        />
      </aside>
    </StyledContainer>
  );
};
