import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NoSearchFound } from "../NotFoundSection";
import { Table, Title, Button } from "@safelyq/bulma-ui-library";
// import { useProfile } from 'hooks/useProfile';

const StyledList = styled.td.attrs(() => ({
  className: "py-3 pl-2",
}))``;

export const TableView = ({ ...props }) => {
  const { locations, googleReserve } = props;

  // const { displayName } = useProfile();

  const noLocationFound = locations.length === 0;

  return (
    <Fragment>
      <Table fullwidth hoverable>
        <thead>
          <tr>
            <th>#</th>
            <th className="pl-2">Business Name</th>
            <th></th>
            <th>Code</th>
            <th>Organization</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody className="has-text-black">
          {noLocationFound ? (
            <tr>
              <td colSpan={4}>
                <NoSearchFound />
              </td>
            </tr>
          ) : (
            locations.map(
              (
                { id, name, organization, isGoogleReserve },
                index
              ) => (
                <tr className="py-3" key={`locations-${index}`}>
                  <StyledList>{index + 1}</StyledList>
                  <StyledList className="is-flex is-align-items-center">
                    <Button color="info" className="icon-button">
                      {/*<Icon name='code' />*/}
                      {String(name).charAt(0).toUpperCase()}
                    </Button>
                    <Link to={googleReserve ? `/admin/reserve-with-google/${id}` : `/admin/appointments-log/${id}`} className="ml-2">
                      <Title size="6">{name}</Title>
                      {/*<Subtitle size='6'>{displayName}</Subtitle>*/}
                    </Link>
                  </StyledList>
                  <StyledList>
                    {isGoogleReserve &&

                      <img src='https://res.cloudinary.com/safelyq/image/upload/v1706108734/GoogleReserve/GoogleLogo_f1puvw.png' alt='' style={{ height: '40px', width: '40px' }}></img>

                    }
                  </StyledList>
                  <StyledList>{id}</StyledList>
                  <StyledList>{organization?.name ?? ""}</StyledList>
                  <StyledList>
                    <span className="tag is-primary is-light">Admin</span>
                  </StyledList>
                </tr>
              )
            )
          )}
        </tbody>
      </Table>
    </Fragment>
  );
};
