import React, { useState, useEffect } from 'react'
import { Title, Button, Label, Field, Control, Modal } from '@safelyq/bulma-ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_BUSINESS_FORM, GET_GOOGLE_FORM_QUESTIONS } from './requests';
import { useParams, useHistory } from 'react-router-dom';
import AddQuestions from './AddQuestions';
import SelectedQuestions from './SelectedQuestions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const CreateForm = () => {
    const { businessId } = useParams();
    const history = useHistory();
    const [formModel, setFormModel] = useState({
        title: "",
        description: ""
    })
    const [questions, setquestions] = useState([])
    const [selectedQuestionsarr, setselectedQuestionsarr] = useState([])
    const [allowForm, setAllowForm] = useState(true)
    const [alertShown, setAlertShown] = useState(false);
    const [showpop, isShowPopup] = useState(false)

    const googleFormAllowed = useSelector(state => state?.business?.businessFeatures?.googleFormAllowed)

    const [loader, setloader] = useState(false)
    const [createBusinessForm] = useMutation(CREATE_BUSINESS_FORM);
    useQuery(GET_GOOGLE_FORM_QUESTIONS, {

        fetchPolicy: 'no-cache',
        skip: !googleFormAllowed, // skip the api call if the google forms are not allowed 
        onCompleted: (data) => {
            setselectedQuestionsarr(data.getGoogleFormQuestions)
        }
    })
    // let id = parseInt(businessId)
    // const { data: currentBusinessId } = useQuery(GET_BUSINESS_BY_ID, {
    //     fetchPolicy: 'no-cache',
    //     notifyOnNetworkStatusChange: true,
    //     variables: { id },
    // });

    // let googleFormAllowed = currentBusinessId?.getBusinessById?.businessSubscription?.subscriptionPlan?.features?.googleFormAllowed;

    useEffect(() => {
        setAllowForm(googleFormAllowed)
    }, [setAllowForm, googleFormAllowed])

    if (googleFormAllowed !== undefined && !googleFormAllowed && !alertShown) {
        isShowPopup(true);
        setAlertShown(true);
    }

    const handleUpgrade = () => {
        history.push({ pathname: `/admin/pricing/${businessId}`, });
    };

    const handleCancel = () => {
        history.push(`/admin/appointments-log/${businessId}`);
        isShowPopup(false);
    };

    const handleChange = (e) => {
        setFormModel((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleAddQuestion = () => {
        setquestions((prev) => ([
            ...prev,
            {
                name: "",
                isRequired: false,
                isLongAnswer: false
            }
        ]))
    }

    const handleInputChange = (e, index) => {
        let { name, value } = e.target;
        const updatedInputs = [...questions];
        if (name === "isRequired" || name === "isLongAnswer") {
            updatedInputs[index][name] = e.target.checked;
        }
        else {
            updatedInputs[index][name] = value;
        }
        setquestions(updatedInputs)
    }

    const handleInputChange2 = (e, index) => {
        let { name } = e.target;
        const updatedInputs = [...selectedQuestionsarr];
        updatedInputs[index][name] = e.target.checked
        setselectedQuestionsarr(updatedInputs)
    }

    const handleQuestionCancel = (index) => {
        const newQuestions = questions.filter((quest, i) => i !== index)
        setquestions(newQuestions)
    }

    const handleSubmit = async () => {
        if (formModel.title === "") {
            toast.error("Form title is required")
            return
        }
        try {
            setloader(true)
            const response = await createBusinessForm({ variables: { createFormInput: { businessId: businessId, ...formModel, questions: questions, selectedQuestions: selectedQuestionsarr } }, })
            if (response.data.createGoogleForm.isSaved) {

                history.push({ pathname: `/admin/forms/${businessId}`, });
            }
            else {
                toast.error(response.data.createGoogleForm.errorMessage)
            }
            setloader(false)

        } catch (error) {
            console.log(error);
            setloader(false)

        }

    }
    return (
        <>
            {showpop &&
                <Modal active={showpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Body>
                            <div className=' content'>
                                <p>Upgrade your business subscription to access this operation.</p>
                            </div>
                            <div className='is-flex is-justify-content-space-evenly'>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
                            </div>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            }

            <div className='bscontainer p-5'>
                <div className='row'>
                    <div className='col-lg-12 mb-4'>
                        <Title size='5' className='mb-4'>   Create Form  </Title>
                    </div>
                    <div className='col-lg-4'>
                        <Field>
                            <Control>
                                <Label className='has-text-info'>Form Title</Label>
                                <input style={{ width: "200px" }} className='input' name='title' onChange={handleChange} value={formModel.title} disabled={!allowForm} />
                            </Control>
                        </Field>
                    </div>
                    <div className='col-lg-4 mb-4'>
                        <Field>
                            <Control>
                                <Label className='has-text-info'>Form Description</Label>
                                <input style={{ width: "200px" }} className='input' name='description' onChange={handleChange} value={formModel.description} disabled={!allowForm} />
                            </Control>
                        </Field>
                    </div>
                    <SelectedQuestions
                        selectedQuestionsarr={selectedQuestionsarr}
                        handleInputChange2={handleInputChange2}
                        allowForm={allowForm}

                    />
                    <AddQuestions
                        handleAddQuestion={handleAddQuestion}
                        questions={questions}
                        handleInputChange={handleInputChange}
                        handleQuestionCancel={handleQuestionCancel}
                        allowForm={allowForm}
                    />


                    <div className='col-lg-12'>
                        <Button onClick={handleSubmit} loading={loader} color='info' disabled={!allowForm}>Create Form</Button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CreateForm;
