import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Section, Container, Box, Title, Switch, Icon } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { LocationSetup, ServiceProvidersSetup } from '../../components';
import { toast as showToast } from 'react-toastify';
import { Spin } from 'antd';
import {
  BusinessProvider,
  useBusinessDispatch,
  useBusinessState,
} from './context';
import { useData } from './hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setBusinessName, setBusinessCategory } from 'Redux/BusinessSlice/Business';

const CreateIcon = styled(Icon).attrs(() => ({
  className: 'has-text-info',
}))`
  font-size: 24px;
  cursor: pointer;
`;

export const ManageBusiness = () => {

  const [isCreateServiceProvider, setCreateServiceProvider] = useState(false);
  const { isLoading, data, refetch, onSaveBusiness, onUpdateServiceProviderStatus, onUpdateBusinessOnlineStatus, isSaveLoading, updateToastMessages, statusError, businessStatusLoading } = useData();
  const [allowToast, setAllowToast] = useState(false);
  const [businessActiveStatus, setBusinessActiveStatus] = useState();
  const state = useSelector(state => state);
  const businessName = state?.business?.businessName;
  const { id = 0 } = useParams();

  const { business, categories, countries, organizations } = data || {};
  const dispatch = useDispatch();

  const { businessPermissions } = business || {};

  let { isAdmin } = businessPermissions || {};

  const [businessHoursValidator, setBusinessHoursValidator] = useState({});

  const [isGoogleReserve, setIsGoogleReserve] = useState(true);

  // set loading status of all services to false when response is not success
  useEffect(() => {
    if (!businessStatusLoading && statusError) {
      let dummy = []
      dummy.push({ id: id, name: business?.name, isActive: business?.isActive, isLoading: false });
      const array = business?.children?.map((subBusiness) => {
        dummy.push({
          id: subBusiness?.id,
          name: subBusiness?.name,
          isActive: subBusiness?.isActive,
          isLoading: false
        });
      });
      setBusinessActiveStatus(dummy)
    }
  }, [businessStatusLoading])

  useEffect(() => {
    if (business && business.id) {
      const locationHoursValidator = {
        id: business?.id,
        hasValidHours:
          business?.businessHours?.find(
            (hour) =>
              moment(hour?.openingTime).format('HH:mm') !== '00:00' &&
              moment(hour?.closingTime).format('HH:mm') !== '00:00'
          ) !== undefined,
      };
      const serviceProviderHoursValidator = business?.children
        ?.map(({ id, businessHours }, index) => {
          return {
            id: id,
            hasValidHours:
              businessHours?.find(
                (hour) =>
                  moment(hour?.openingTime).format('HH:mm') !== '00:00' &&
                  moment(hour?.closingTime).format('HH:mm') !== '00:00'
              ) !== undefined,
          };
        })
        .reduce(
          (obj, item) => ({ ...obj, [`${item.id}`]: item.hasValidHours }),
          {
            [`${locationHoursValidator.id}`]:
              locationHoursValidator.hasValidHours,
          }
        );
      setBusinessHoursValidator({ ...serviceProviderHoursValidator });

      // set business name and category to state
      dispatch(setBusinessCategory(business?.businessCategory));
      // dispatch(setBusinessCategory(business?.businessCategory?.name));
      dispatch(setBusinessName(business?.name))
    }

    if (business?.canBusinessOptIn === false) {
      setIsGoogleReserve(false);
    } else {
      setIsGoogleReserve(true);
    }

    // set loading status of all services to false
    let dummy = []
    dummy.push({ id: id, name: business?.name, isActive: business?.isActive, isLoading: false });
    const array = business?.children?.map((subBusiness) => {
      dummy.push({
        id: subBusiness?.id,
        name: subBusiness?.name,
        isActive: subBusiness?.isActive,
        isLoading: false
      });
    });
    setBusinessActiveStatus(dummy)

  }, [business]);

  // show toast when toggle state updates
  // Below useeffect will run only when the isActive status will change
  useEffect(() => {
    if (allowToast) {
      showToast.success('Business status changed successfully.');
    }
  }, [business?.isActive, business?.children?.map(subBusiness => subBusiness?.isActive).join(',')]);

  const udpateBusinessHoursStatus = (id, updateValue) => {
    setBusinessHoursValidator({
      ...businessHoursValidator,
      [`${id}`]: updateValue,
    });
  };

  if (isLoading) return <Loader isFullScreen={true} />;

  if (!id) isAdmin = true;

  const handleCreateServiceProvider = () => {
    setCreateServiceProvider(!isCreateServiceProvider);
  }

  const handleBusinessStatusChange = (onlineStatus) => {
    onUpdateBusinessOnlineStatus({
      variables: {
        businessId: id,
        onlineStatus,
      },
    })

    // allow toast
    setAllowToast(true);

    // set the loading on of the service that is changed
    const newArray = businessActiveStatus?.map(business => {
      if (business.id === id) {
        return { ...business, isLoading: true }
      }
      else {
        return business
      }
    })
    setBusinessActiveStatus(newArray)
  };

  return (
    <Section className='mainSection'>
      <Container>
        <BusinessProvider>
          <Box className='mainBox' shadow={Boolean(id)}>
            {isAdmin ? (
              <Fragment>
                <div className='is-flex is-align-items-center is-justify-content-space-between'>
                  {id ? (
                    <Fragment>
                      <div>
                        <Title size='6' className='mb-0'>
                          <Icon name='compass' />
                          {businessName}
                        </Title>
                        <small>
                          <em>Add/Edit Your Location</em>
                        </small>
                      </div>


                      <div className='is-flex'>
                        {/* Loader with switch */}
                        <Spin spinning={businessActiveStatus?.some(business => business.id === id && business.isLoading)}>
                          <Switch
                            value={business?.isActive}
                            onValueChange={(checked) =>
                              handleBusinessStatusChange(checked)
                            }
                          />
                        </Spin>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
                {id ? <hr className='m-3' /> : null}
                <LocationSetup
                  business={business}
                  countries={countries}
                  categories={categories}
                  organizations={organizations}
                  refetch={refetch}
                  isLoading={isSaveLoading}
                  onSaveBusiness={onSaveBusiness}
                  onUpdateBusinessOnlineStatus={onUpdateBusinessOnlineStatus}
                  updateToastMessages={updateToastMessages}
                  businessHoursValidator={businessHoursValidator}
                  udpateBusinessHoursStatus={udpateBusinessHoursStatus}
                  isGoogleReserve={isGoogleReserve}
                />
                <br />
              </Fragment>
            ) : null}
            {id ? (
              <Fragment>
                <div className='is-flex is-align-items-center is-justify-content-space-between ml-4'>
                  <div>
                    <Title size='6' className='mb-0'>  <Icon name='female' />   Service Provider   </Title>
                    <small>   <em>Setup Service Providers</em>    </small>
                  </div>
                  {!isCreateServiceProvider && <CreateIcon name='plus-square' onClick={handleCreateServiceProvider} />}

                </div>
                <hr className='m-3' />
                <ServiceProvidersSetup
                  isAdmin={isAdmin}
                  business={business}
                  countries={countries}
                  categories={categories}
                  isLoading={isSaveLoading}
                  isCreateServiceProvider={isCreateServiceProvider}
                  handleCreateServiceProvider={handleCreateServiceProvider}
                  refetch={refetch}
                  setAllowToast={setAllowToast}
                  businessActiveStatus={businessActiveStatus}
                  setBusinessActiveStatus={setBusinessActiveStatus}
                  setCreateServiceProvider={() =>
                    setCreateServiceProvider(false)
                  }
                  onSaveBusiness={onSaveBusiness}
                  onUpdateServiceProviderStatus={onUpdateServiceProviderStatus}
                  businessHoursValidator={businessHoursValidator}
                  udpateBusinessHoursStatus={udpateBusinessHoursStatus}
                />
              </Fragment>
            ) : null}
          </Box>
        </BusinessProvider>
      </Container>
    </Section>
  );
};

export { useBusinessDispatch, useBusinessState };
