import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ApolloRoute } from 'routes/ApolloRoutes';
import { AdminLayout } from '@safelyq/base-feature';
import { ViewHome, AdminLandingPage } from '@safelyq/business-admin';
import { ManageLogs } from '@safelyq/appointment-logs-feature';
import { ManageNotifications } from '@safelyq/admin-notifications';
import { ManageReports } from '@safelyq/reports-feature';
import { ManageAdminMessages } from '@safelyq/admin-messages-feature';
import { ManageAppointment, AddAppointment, } from '@safelyq/admin-appointment-feature';
import { AddCampaign, ManageCampaign, ManageFiles } from '@safelyq/admin-campaign-feature';
import { ManageAdminSettings } from '@safelyq/account-feature';
import { ManageBusiness, ManageReviews, CreateOrganization, ManageReserveWithGoogle, ManageConsole } from '@safelyq/manage-business';
import { ManagePromotions, } from '@safelyq/admin-promotions-feature';
import { ManageMembers } from '@safelyq/manage-members';
import useGaTracker from 'hooks/useGaTracker';
// import { NotFound } from 'components/NotFound';
import ViewForms from 'features/admin-forms-feature/ViewForms';
import CreateForm from 'features/admin-forms-feature/CreateForm';
import ManageSubscription from './../../features/manage-business/src/routes/ManageSubscription/index';
import StripeAccount from 'components/AdminStripeAccount';
const NotFound = React.lazy(() => import('components/NotFound'));

export const AdminRoutes = ({ profile,
  //  activeItem, setActiveItem
}) => {
  const { pathname: tempPath } = useLocation();
  const pathname = tempPath.toLowerCase();

  const [found, setFound] = useState(true);
  useGaTracker();
  useEffect(() => {
    if (pathname.includes('/admin/businesses/') && pathname !== '/admin/businesses/optout') {
      setFound(false);
    } else {
      setFound(true);
    }
  }, []);



  const isAllowSideBar =
    pathname !== '/admin' &&
    pathname !== '/admin/' &&
    !pathname.startsWith('/admin/businesses') &&
    pathname !== '/admin/notifications' &&
    pathname !== '/admin/account/setting' &&
    pathname !== '/admin/account/notifications' &&
    pathname !== '/admin/account/verify-phone' &&
    pathname !== '/admin/create-organization' &&
    pathname !== '/admin/manage-business';

  const isAllowFullScreen = pathname.includes('/admin/appointments-log') || pathname.includes('/reports-summary');
  const isAllowNotifications =
    pathname.includes('/appointments') ||
    pathname.includes('/create-appointment') ||
    pathname.includes('/messages') ||
    pathname.includes('/manage-reviews') ||
    pathname.includes('/reports-summary') ||
    pathname.includes('/admin/notifications') ||
    pathname.includes('/promotions') ||
    pathname.includes('/campaign') ||
    pathname.includes('/create-campaign') ||
    pathname.includes('/manage-users') ||
    pathname.includes('/create-form') ||
    pathname.includes('/forms') ||
    pathname.includes('/view-campaign') ||
    pathname.includes('/manage-files') ||
    pathname.includes('/manage-console') ||
    pathname.includes('/coupons') ||
    pathname.includes('/reserve-with-google') ||
    pathname.includes('/pricing') ||
    pathname.includes('/manage-business');


  const pathId = String(pathname).substring(pathname.lastIndexOf('/') + 1);
  const businessId = Number(pathId) ? parseInt(pathId) : 0;

  return (
    !found ? (
      <NotFound />
    ) : (

      <Switch>
        <AdminLayout
          isAllowSideBar={isAllowSideBar}
          isAllowFullScreen={isAllowFullScreen}
          isAllowNotifications={isAllowNotifications}
          businessId={businessId}
          {...profile}
        >
          <ApolloRoute exact path='/admin' isNavbarHide={true} profile={profile}
            component={() =>
              <AdminLandingPage />
            }
          />
          <ApolloRoute secure exact path='/admin/businesses/:reserve?' isNavbarHide={true} profile={profile}
            component={() =>
              <ViewHome />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/appointments-log/:businessId' profile={profile}
            component={() =>
              <ManageLogs />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/appointments/:businessId' profile={profile}
            component={() =>
              <ManageAppointment />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/create-appointment/:businessId' profile={profile}
            component={() =>

              <AddAppointment />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/view-campaign/:businessId' profile={profile}
            component={() =>
              <ManageCampaign />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/create-campaign/:businessId' profile={profile}
            component={() =>
              <AddCampaign />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-files/:businessId' profile={profile}
            component={() =>
              <ManageFiles />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/notifications/:businessId' profile={profile}
            component={() =>
              <ManageNotifications />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/reports-summary/:businessId' profile={profile}
            component={() =>
              <ManageReports />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/messages/:businessId' profile={profile}
            component={() =>
              <ManageAdminMessages />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-business/holidays/:businessId' profile={profile}
            component={() =>
              <ManageBusiness />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-reviews/:businessId' profile={profile}
            component={() =>
              <ManageReviews />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-business/:id?' profile={profile}
            component={() =>
              <ManageBusiness />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-console/:id?' profile={profile}
            component={() =>
              <ManageConsole />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/reserve-with-google/:id' profile={profile}
            component={() =>
              <ManageReserveWithGoogle />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/pricing/:id' profile={profile}
            component={() =>
              <ManageSubscription />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/payment/:id' profile={profile}
            component={() =>
              <StripeAccount />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/coupons/:id?' profile={profile}
            component={() =>
              <ManagePromotions />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/create-organization' profile={profile}
            component={() =>
              <CreateOrganization />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/account' profile={profile}
            component={() =>
              <ManageAdminSettings />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/account/setting' profile={profile}
            component={() =>
              <ManageAdminSettings />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/account/notifications' profile={profile}
            component={() =>
              <ManageAdminSettings />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/account/verify-phone' profile={profile}
            component={() =>
              <ManageAdminSettings />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/manage-users/:id' profile={profile}
            component={() =>
              <ManageMembers />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/forms/:businessId' profile={profile}
            component={() =>
              <ViewForms />
            }
          />
          <ApolloRoute secure exact isNavbarHide={true} path='/admin/create-form/:businessId' profile={profile}
            component={() =>
              <CreateForm />
            }
          />
          {/* <Route path='*' render={(props) => <NotFound />} /> */}
        </AdminLayout>
      </Switch>
    )
  )
} 