import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {
  Section,
  Container,
  Level,
  Columns,
  Column,
  Button,
  Title,
  Icon,
} from '@safelyq/bulma-ui-library';
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useData } from './hooks';
import { states } from './constants';
import 'react-accessible-accordion/dist/fancy-example.css';

export const CreateOrganization = () => {
  const [values, setValues] = useState({});
  const [phone, setphone] = useState("")

  const { onCreateOrganization, isLoading } = useData();

  const handleValuesChange = (e) => {
    let { name, value } = e.target
    setValues({ ...values, [name]: value });
  }

  const handlePhoneChange = (val) => {
    setphone(val)
  }




  const handleSubmit = (e) => {
    e.preventDefault();

    onCreateOrganization({ variables: { organizationInput: { ...values, phone: phone } } });
  };

  return (
    <Section>
      <Container>
        <Columns multiline>
          <Column size='12'>
            <Level>
              <Level.Left>
                <Link to='/admin/manage-business'>
                  <Button color='light'>
                    <Icon name='arrow-left' />
                    <span>Back</span>
                  </Button>
                </Link>
              </Level.Left>
              <Level.Right>
                <Link to='/admin/manage-business'>
                  <Button color='info'>Create Business</Button>
                </Link>
              </Level.Right>
            </Level>
          </Column>
          <Column size='12'>
            <Title size='5'>Create new Organization</Title>
          </Column>
          <Column size='12'>
            <Accordion preExpanded={['1']}>
              <AccordionItem uuid={'1'}>
                <AccordionItemPanel>
                  <form onSubmit={handleSubmit}>
                    <Columns multiline>
                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>
                            Name <span className='has-text-danger'>*</span>
                          </label>
                          <div className='control'>
                            <input
                              name='name'
                              required={true}
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>
                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>Contact Name</label>
                          <div className='control'>
                            <input
                              name='contactName'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>


                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>Phone Number</label>
                          {/* <div className='control'>
                            <input
                              name='phone'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div> */}
                          <div className='control'>
                            <PhoneInput
                              inputClass='input'
                              countryCodeEditable={false}
                              country={'us'}
                              value={phone}
                              onChange={handlePhoneChange}
                            />
                            {/* {errors.phone && (
                              <p className='help is-danger'>Phone Number is required</p>
                            )} */}
                          </div>
                        </div>
                      </Column>
                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>Email Address</label>
                          <div className='control'>
                            <input
                              name='email'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>
                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>Address 1</label>
                          <div className='control'>
                            <input
                              name='address1'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>
                      <Column size='6'>
                        <div className='field'>
                          <label className='label'>Address 2</label>
                          <div className='control'>
                            <input
                              name='address2'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>
                      <Column size='4'>
                        <div className='field'>
                          <label className='label'>
                            City <span className='has-text-danger'>*</span>
                          </label>
                          <div className='control'>
                            <input
                              name='city'
                              required={true}
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>
                      <Column size='4'>
                        <div className='field'>
                          <label className='label'>State</label>
                          <div className='control'>
                            <input
                              list='states'
                              name='state'
                              value={values.state}
                              className='input'
                              onChange={handleValuesChange}
                            />
                            <datalist id='states'>
                              {states.map(({ name }, index) => (
                                <option key={`state-${index}`} value={name} />
                              ))}
                            </datalist>
                          </div>
                        </div>
                      </Column>
                      <Column size='4'>
                        <div className='field'>
                          <label className='label'>Zip Code</label>
                          <div className='control'>
                            <input
                              name='zipCode'
                              className='input'
                              onChange={handleValuesChange}
                            />
                          </div>
                        </div>
                      </Column>


                      <Column size='12'>
                        <Button
                          type='submit'
                          color='info'
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          Save
                        </Button>
                      </Column>
                    </Columns>
                  </form>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </Column>
        </Columns>
      </Container>
    </Section>
  );
};
