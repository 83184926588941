import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { DELETE_BUSINESS_FORM, GET_BUSINESS_FORMS } from './requests'
import { useParams, useHistory } from 'react-router-dom';
import { Table, Title, Container, Modal, Button } from '@safelyq/bulma-ui-library';
import { FaEye, FaPencilAlt, FaTrashAlt, FaWindowClose, FaKey } from "react-icons/fa";
import { toast } from 'react-toastify';
import PermissionPopup from './PermissionPopup';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';
import { useSelector } from 'react-redux';

const ViewForms = () => {
    const [allForms, setAllForms] = useState([])
    const [iframeUrl, setiframeUrl] = useState("")
    const [permisionPopup, setpermisionPopup] = useState({
        status: false,
        formId: 0,
    })
    const [loader, setloader] = useState(false)
    const history = useHistory();

    const [alertshowpop, isAlertShowPopup] = useState(false)

    const businessFeatures = useSelector((state) => state?.business?.businessFeatures);

    const { businessId } = useParams();
    const { data, refetch } = useQuery(GET_BUSINESS_FORMS, {
        variables: { id: parseInt(businessId) },
        skip: !businessFeatures?.googleFormAllowed, // skip the api call if the google forms are not allowed 
        fetchPolicy: 'no-cache',
    })
    const [deleteBusinessForm] = useMutation(DELETE_BUSINESS_FORM);

    const onDeleteFile = async (e, ID) => {
        e.stopPropagation()

        if (!window.confirm('Are you sure you want to delete the record?')) return;
        try {
            setloader(true)
            const response = await deleteBusinessForm({ variables: { businessGoogleFormId: ID }, })
            await refetch()
            if (response.data.deleteGoogleForm.isSaved) {
                toast.success("Form deleted successfully")
            }
            else {
                toast.warning(response.data.deleteGoogleForm.errorMessage)
            }
            setloader(false)

        } catch (error) {
            setloader(false)
        }

    }

    const handleIframeUrl = (url) => {
        setiframeUrl(url)
    }

    const closeIframe = () => {
        setiframeUrl("")
    }

    const openPermissiononPopup = (data) => {
        setpermisionPopup({
            status: true,
            formId: data.id,
        })
    }
    const closePermissiononPopup = () => {
        setpermisionPopup({
            status: false,
            formId: 0,
        })
    }

    const iframeStyling = {
        height: 600,
        overflow: "auto",
        border: "5px solid #8bc0eb",
        borderRadius: "4px",
        marginTop: "30px",
        padding: 0
    }


    useEffect(() => {
        if (data) {
            if (data.getBusinessById) {
                setAllForms(data.getBusinessById.businessGoogleForms)

            }
        }
    }, [data])

    // Show popup that you are not subscribed for this module
    useEffect(() => {
        if (businessFeatures !== null) {
            businessFeatures?.googleFormAllowed === false ? isAlertShowPopup(true) : isAlertShowPopup(false);
        }
    }, [businessFeatures])

    const handleUpgrade = () => {
        history.push(`/admin/pricing/${businessId}`);
    };

    const handleCancel = () => {
        history.push(`/admin/appointments-log/${businessId}`);
        isAlertShowPopup(false);
    };
    return (
        <>
            {alertshowpop &&
                <Modal active={alertshowpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Body>
                            <div className=' content'>
                                <p>Upgrade your business subscription to access this operation.</p>
                            </div>
                            <div className='is-flex is-justify-content-space-evenly'>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
                            </div>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            }

            {loader && <FullPageLoader />}
            {permisionPopup.status && <PermissionPopup allForms={allForms} refetch={refetch} formId={permisionPopup.formId} isOpen={permisionPopup.status} onClose={closePermissiononPopup} />}
            <div className='bscontainer p-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Title size='5' className='mb-4'> Forms   </Title>
                    </div>
                    <div className='col-lg-12'>
                        {allForms.length === 0 ? (
                            <section className='hero'>
                                <div className='hero-body'>
                                    <Container className='has-text-centered'>
                                        <img src='/images/search-not-found.svg' className='mb-2' width={130} />
                                        <Title size='5'>No records found</Title>
                                        {/* <Subtitle size='6'>
                                                    {subtitle || (
                                                        <span>As appointments are set, they will appear here</span>
                                                    )}
                                                </Subtitle> */}
                                    </Container>
                                </div>
                            </section>
                        ) : (
                            <Table fullwidth bordered  >
                                <thead>
                                    <tr>
                                        <th className='pl-2'>Title</th>
                                        <th className='pl-2'>Response Count</th>
                                        <th className='pl-2'>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='has-text-black'>

                                    {allForms.map((form) => {
                                        return (
                                            <tr>
                                                <td>{form.formTitle}</td>
                                                <td> <a style={{ color: "blue", textDecoration: "underline" }} title="View Responsess" className='mx-4' rel="noreferrer" target='_blank' href={`${form.editFormUrl}#responses`}> {form.responseCount}</a></td>
                                                <td className='has-text-right'>
                                                    <span style={{ cursor: "pointer" }} onClick={() => openPermissiononPopup(form)} title="View Permission" className='mx-4' >  <FaKey /></span>
                                                    <a title="View Form" className='mx-4' rel="noreferrer" target='_blank' href={form.viewFormUrl}>  <FaEye /></a>
                                                    <a title="Edit Form" className='mx-4' rel="noreferrer" target='_blank' href={form.editFormUrl}><FaPencilAlt /></a>
                                                    <span style={{ cursor: "pointer" }} onClick={(e) => onDeleteFile(e, form.id)} title="Delete Form" className='mx-4'  ><FaTrashAlt /></span>
                                                    <span className='mx-4' onClick={() => handleIframeUrl(form.viewFormUrl)}>View in Iframe</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        )}

                    </div>

                    {iframeUrl && <span style={{ cursor: "pointer" }} onClick={closeIframe} title="Delete Form" className='mx-4'  ><FaWindowClose /></span>}
                    {iframeUrl && <div className='col-lg-12' style={iframeStyling}> <iframe src={iframeUrl} height="800" width="100%" title="Iframe Example"></iframe> </div>}

                </div>

            </div>
        </>


    )
}

export default ViewForms;
