import React, { Fragment } from 'react'
import { Field, Control, Button, Label, Icon, TextInput, Columns, Column } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { ImagesComponent } from '../ImagesComponent';
import { useState } from 'react';
import { TemplatePreview } from '../TemplatePreview';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

export const MessageDetails = (props) => {
    const [uploadImageLoading, setUploadImageLoading] = useState(false);
    const businessCategory = useSelector(state => state?.business?.businessCategory);

    const {
        audienceButtonDisable,
        campaign,
        setCampaign,
        coupon,
        existingBusinessCoupon,
        handleRadioButtonChange,
        sMS,
        setSMS,
        selectedCoupon,
        handleCampaignChangeGsm,
        campaignFiles,
        setCampaignFiles,
        handleViewChange,
        campaignGroups,
        selectedMessageType,
        setSelectedMessageType,
        marketingCampaignTemplates
    } = props;

    let { businessId } = useParams();
    businessId = parseInt(businessId);

    const [isOpen, setIsOpen] = useState(false);

    // Reorder the campaignGroups array to place MMS and SMS campaigns first
    const reorderedCampaigns = [...campaignGroups].sort((a, b) => {
        if (a.name === "MMS" || a.name === "SMS") return -1; // Place MMS and SMS first
        return 0;
    });

    const CheckEmptyFields = () => {
        // For all tab
        if (selectedMessageType.name === "All") {
            if (isEmpty(campaign.details)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            // For Campaign Groups
            // If message text is empty or null and selected, then disable the run campaign button
            const selectedCampaignTypes = campaignGroups?.filter(campaign => campaign.isSelected && (campaign?.messageText === null || campaign?.messageText?.trim() === ""));

            if (selectedCampaignTypes.length === 0) {
                return false;
            }
            else {
                return true
            }
        }
    }

    const handleUploadImage = (newImage) => {
        let newFiles = newImage?.map(image => {
            const file = {
                fileName: image.fileName,
                contentType: image.contentType,
                url: image.url || '',
                base64String: image.base64String || '',
                fileSize: image?.fileSize
                // isDefault: image.isDefault
            }
            return file;
        })

        setCampaignFiles([...campaignFiles, ...newFiles])
        setUploadImageLoading(false);
    }

    const handleDeleteImage = (deleteImage) => {
        const updatedImages = campaignFiles?.filter((file) => file.fileName !== deleteImage.fileName)
        setCampaignFiles(updatedImages)
    }

    // Props
    const templatePreviewProps = {
        isOpen,
        setIsOpen,
        marketingCampaignTemplates,
        campaignGroups,
        campaign,
        selectedCoupon,
        coupon,
        sMS,
        setSMS,
        selectedMessageType,
        campaignFiles
    }

    return (
        <Fragment>
            <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
                <div className='box'>
                    <Columns multiline className='pb-3' style={{ position: "relative" }}>
                        <Column size='12'>
                            <Field>
                                <Control>
                                    <div className='is-flex is-justify-content-space-between'>
                                        <Label className='has-text-info'>
                                            Message Details <span className='ml-1 has-text-danger'>*</span>
                                            {audienceButtonDisable && CheckEmptyFields() && coupon.id === null && <UserHint>Please Enter Custom Message or Select Coupon</UserHint>}
                                            {/* {audienceButtonDisable && isEmpty(campaign.details) && coupon.id === null && <UserHint>Please Enter Custom Message or Select Coupon</UserHint>} */}
                                        </Label>
                                        <div className='is-flex is-clickable has-text-info' onClick={() => setIsOpen(true)}>
                                            <Label className='has-text-info is-clickable'>Preview</Label>
                                            <Icon name='eye' className="pl-2 py-auto" />
                                        </div>
                                    </div>
                                </Control>
                            </Field>
                        </Column>
                        <Column size='6' className='my-auto pr-5'>
                            <Field>
                                <Control>
                                    <div className='columns'>
                                        <div className='column is-1 is-flex is-align-items-center'>
                                            <Label className='radio'>
                                                <LargeRadio
                                                    id='false'
                                                    name='radioButton'
                                                    type='radio'
                                                    value={selectedMessageType?.name === "All" ? campaign?.details : campaignGroups.find(camp => camp?.name === campaign?.name && camp?.message)}
                                                    defaultChecked={!existingBusinessCoupon}
                                                    // defaultChecked={existingCampaign && details || !existingBusinessCoupon}
                                                    onChange={handleRadioButtonChange}
                                                />
                                            </Label>
                                        </div>
                                        <div className='column is-11 py-0'>
                                            <Field>
                                                {!selectedCoupon && <Label className='has-text-info is-flex is-justify-content-start'>
                                                    <div class="tabs is-small p-0 m-0" style={{ overflow: "scroll" }}>
                                                        <ul>
                                                            <li className={selectedMessageType?.name === "All" && "has-background-grey-lighter"} onClick={() => setSelectedMessageType({ name: "All", isSelected: true })}>
                                                                <a>
                                                                    All
                                                                </a>
                                                            </li>
                                                            {
                                                                reorderedCampaigns.filter(camp => camp?.isSelected).map(camp => {
                                                                    return <li className={selectedMessageType?.name === camp?.name && "has-background-grey-lighter"} onClick={() => {
                                                                        setSelectedMessageType(camp);
                                                                        camp?.name === "MMS" ? setCampaign({ ...campaign, isClipFile: true }) : setCampaign({ ...campaign, isClipFile: false })
                                                                    }}>
                                                                        {camp?.name === "SMS"
                                                                            ?
                                                                            <>
                                                                                <a>   {`SMS (${sMS?.sMSSegment} Segments)`}
                                                                                    <i class="fa fa-info-circle ml-2 fa-lg" data-tip="The size of the segments includes template text" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ReactTooltip place="top" effect="float" />
                                                                            </>
                                                                            :
                                                                            <a>
                                                                                {camp?.name}
                                                                            </a>}

                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </Label>}
                                                <Control>
                                                    <textarea
                                                        class="textarea"
                                                        rows={2}
                                                        className='input textarea'
                                                        name={selectedMessageType ? selectedMessageType?.name : "SMS"}
                                                        placeholder={selectedMessageType ? `Custom message for ${selectedMessageType?.name}` : "Custom message"}
                                                        value={selectedMessageType?.name === "All" ? campaign?.details : campaignGroups.find(camp => camp.name === selectedMessageType?.name)?.messageText || ""}
                                                        disabled={selectedCoupon}
                                                        onChange={handleCampaignChangeGsm}
                                                    >
                                                    </textarea>

                                                    {/* if only sms is selected than do not show image component  */}
                                                    {!selectedCoupon &&
                                                        campaignGroups?.some(file => file?.isSelected) &&
                                                        selectedMessageType.name !== "SMS" &&
                                                        !((campaignGroups?.filter(campaign => campaign?.isSelected).length === 1) &&
                                                            (campaignGroups?.filter(campaign => campaign?.isSelected)[0]?.name === "SMS")) &&
                                                        (
                                                            <div className='pt-2'>
                                                                <ImagesComponent
                                                                    sectionName='mms'
                                                                    onUploadImage={handleUploadImage}
                                                                    onDeleteImage={handleDeleteImage}
                                                                    campaignFiles={campaignFiles}
                                                                    setUploadImageLoading={setUploadImageLoading}
                                                                    uploadImageLoading={uploadImageLoading}
                                                                />
                                                            </div>
                                                        )}
                                                </Control>
                                            </Field>
                                        </div>
                                    </div>
                                </Control>
                            </Field>
                        </Column>
                        {
                            businessCategory?.features?.couponAllowed
                            &&
                            <>
                                <CustomHr />
                                <hr className='is-hidden-desktop is-hidden-tablet is-block-mobile' />
                                <Column size='6' className='my-auto pl-5'>
                                    <Field>
                                        <Control>
                                            <div className='columns'>
                                                <div className='column is-1 is-flex is-align-items-center'>
                                                    <Label className='radio'>
                                                        <LargeRadio
                                                            id='true'
                                                            name='radioButton'
                                                            type='radio'
                                                            value={true}
                                                            defaultChecked={existingBusinessCoupon}
                                                            onChange={handleRadioButtonChange}
                                                        />
                                                    </Label>
                                                </div>

                                                <div className='column is-11 is-flex'>
                                                    <TextInput name='details'
                                                        placeholder='Select Coupon'
                                                        disabled={!selectedCoupon}
                                                        value={coupon.title}
                                                        readOnly
                                                    />

                                                    <Button
                                                        className='ml-2'
                                                        color='info'
                                                        disabled={!selectedCoupon}
                                                        onClick={() => handleViewChange(businessId)}
                                                    >
                                                        Select Coupon
                                                    </Button>
                                                </div>
                                            </div>
                                        </Control>
                                    </Field>
                                </Column>
                            </>
                        }

                    </Columns>
                </div>
            </Column>

            <TemplatePreview {...templatePreviewProps} />

        </Fragment >
    )
}

const UserHint = styled.span.attrs(() => ({
    className: 'help is-danger',
}))`
  font-size: 10px;
  padding-inline: 2px;
}
`;

const LargeRadio = styled.input`
  height: 18px;
  width: 18px;
`;

const CustomHr = styled.div`
    transform: rotate(180deg);
    height: 50%;
    margin: 0;
    border: 1px solid #f7f7f7;
    position: absolute;

  @media only screen and (min-width: 1216px) {
    left: 50%;
    top: 38%;
  }
    @media only screen and (min-width: 769px) {
    left: 50%;
    top: 38%;
  }
  @media only screen and (max-width: 768px) {
    display: none
  }
`;
