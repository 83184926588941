import React, { useState, useRef, useEffect } from 'react';
import Pricing from 'components/Pricing';
import { Loader } from '@safelyq/base-feature';
import { useParams, useHistory } from 'react-router-dom';
import { useCreateBusinessSubscription, useGetPrice, useGetData, useGetSubscription, useStripePayment } from './hooks';
import {
    Columns,
    Column,
    Title,
    Button,
    Modal,
    Container
} from '@safelyq/bulma-ui-library';
import StripeCheckout from 'react-stripe-checkout';
import { setBusinessFeatures } from 'Redux/BusinessSlice/Business';
import { useDispatch } from 'react-redux';

const PRICE = 100;
const name = "Payment";

const ManageSubscription = () => {
    const { id: businessId } = useParams();
    const id = parseInt(businessId);
    const dispatch = useDispatch();

    const [prices, setPrices] = useState({
        monthlyPrice: '',
        yearlyPrice: '',
        finalPrice: ''
    });
    const [plan, setPlan] = useState({
        planId: 0,
        heading: '',
        planType: '',
        planBonus: ''
    })
    const [state, setState] = useState({
        showStripeDropIn: false,
        showpop: false,
        showWarningPopup: false,
        isMonthalyChecked: false,
        isYearlyChecked: false,
        isMonthalyButton: false,
        isYearlyButton: false,
        saveCard: false,
        payButton: false,
    });

    const [selectedDetails, setSelectedDetails] = useState([])

    const { onSaveSubscription, isSaving } = useCreateBusinessSubscription({
        onComplete: () => {
            refetch()
        }
    });
    const { loading, subscriptionPlan } = useGetPrice();
    const { dataLoading, data } = useGetData(id);
    const { isLoading, subscriptionData, refetch } = useGetSubscription(id);
    const { isLoading: isStripLoading, publishableKey, } = useStripePayment();

    const boxRef = useRef();
    const history = useHistory();
    const { getUserProfile } = data;
    const subscription = subscriptionData?.getBusinessById?.businessSubscription || [];
    let userEmail = getUserProfile?.user?.email

    useEffect(() => {
        const features = subscriptionData?.getBusinessById?.businessSubscription?.subscriptionPlan?.features;
        if (features) {
            dispatch(setBusinessFeatures(features))
        }
    }, [subscriptionData])

    if (isLoading || dataLoading || isSaving || isStripLoading || loading) return <Loader isFullScreen={true} />;

    const handleToken = (token) => {
        onSaveSubscription({
            variables: {
                businessId: businessId,
                subscriptionId: plan?.planId,
                tokenId: token.id,
                isRecurring: state.saveCard,
            }
        });
        setState({ ...state, showStripeDropIn: false, showpop: !state.showpop });
    }

    const handleGetStarted = (monthlyPrice, yearlyPrice, tierHeading, planTierType) => {
        if (id) {
            if (yearlyPrice) {
                const matchingPlans = subscriptionPlan?.getSubscriptionPlans.filter(plan => plan.subscriptionTierType === planTierType);
                const bonus = matchingPlans.find(b => b.durationType === 'MONTHLY')
                setPlan((plan) => ({ ...plan, heading: tierHeading, planType: planTierType, planBonus: bonus?.bonus }));
                setState((state) => ({ ...state, showpop: !state.showpop }));
                setPrices(prevPrices => ({
                    ...prevPrices,
                    monthlyPrice: monthlyPrice,
                    yearlyPrice: yearlyPrice
                }));
                setSelectedDetails(matchingPlans)
            }
            else {
                setState((state) => ({ ...state, showWarningPopup: true }));
                setPlan((plan) => ({ ...plan, planType: planTierType }));
            }
        }
        else {
            history.push(
                `/admin/businesses`
            );
        }
    };

    const handleClosePopup = () => {
        setState((state) => ({
            ...state,
            showStripeDropIn: false,
            showpop: false,
            showWarningPopup: false,
            isMonthalyChecked: false,
            isYearlyChecked: false,
            isMonthalyButton: false,
            isYearlyButton: false,
            saveCard: false,
            payButton: false,
        }));
    };

    const handleProceed = () => {
        const matchingPlans = subscriptionPlan?.getSubscriptionPlans.find(p => p.subscriptionTierType === plan.planType);
        onSaveSubscription({
            variables: {
                businessId: businessId,
                subscriptionId: matchingPlans.id,
                tokenId: 0
            }
        });
        setState((state) => ({ ...state, showWarningPopup: false }));
    };

    const handleSubscription = (selectedDetails, price) => {
        const plainDetails = selectedDetails.find(p => {
            return price.includes(p.fee.toString());
        });
        setPrices(prevPrices => ({
            ...prevPrices,
            finalPrice: plainDetails ? Math.round(plainDetails.fee * PRICE) : 0
        }));
        setPlan((plan) => ({ ...plan, planId: plainDetails ? plainDetails.id : 0 }));
        setState((state) => ({ ...state, showStripeDropIn: true, payButton: true }));
    };

    const handleMonthalyCheckboxChange = (event) => {
        if (state.isYearlyChecked) {
            setState((state) => ({ ...state, isYearlyChecked: false, isYearlyButton: false }));
        }
        setState((state) => ({ ...state, isMonthalyChecked: event.target.checked, isMonthalyButton: event.target.checked, saveCard: true, payButton: false }));
    };

    const handleYearlyCheckboxChange = (event) => {
        if (state.isMonthalyChecked) {
            setState((state) => ({ ...state, isMonthalyChecked: false, isMonthalyButton: false }));
        }
        setState((state) => ({ ...state, isYearlyChecked: event.target.checked, isYearlyButton: event.target.checked, payButton: false }));
    };

    const handleSaveCardInfo = (event) => {
        setState((state) => ({ ...state, saveCard: event.target.checked }));
    };

    return (
        <React.Fragment>
            {state.showpop ?
                <Modal active={state.showpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                            <Title size='5' className='mb-3'>
                                {plan?.heading}
                            </Title>
                            <Button onClick={() => handleClosePopup()}>X</Button>
                        </Modal.Card.Head>
                        <Modal.Card.Body>
                            <div className='is-flex is-justify-content-space-around'>
                                <div className='is-flex is-flex-direction-column is-justify-content-space-around'>
                                    <div>
                                        <center className='mb-3 '>
                                            <span className='has-text-link'>
                                                {prices.monthlyPrice}
                                            </span>
                                            <br />
                                            <span className='has-text-link'>
                                                {plan?.planBonus}$ bonus on Campaign
                                            </span>
                                        </center>
                                    </div>
                                    <div>
                                        <label for='monthlycheck'>
                                            <input
                                                id='monthlycheck'
                                                className='m-2'
                                                type="checkbox"
                                                checked={state.isMonthalyChecked}
                                                onChange={handleMonthalyCheckboxChange}
                                            />
                                            <span className='m-2'>I Accept Terms and Conditions</span>
                                        </label>
                                        <center className='mt-2'>
                                            <Button size='larg' color='info' className='button button-info' disabled={!state.isMonthalyButton} onClick={() => handleSubscription(selectedDetails, prices.monthlyPrice, state.isMonthalyChecked)}>Monthly</Button>
                                        </center>
                                    </div>
                                </div>
                                <div className='is-flex is-flex-direction-column is-justify-content-space-between'>
                                    <div>
                                        <center className='mb-3'>
                                            <span className='has-text-link'>
                                                {prices.yearlyPrice}
                                            </span>
                                        </center>
                                    </div>
                                    <div>
                                        <label for='yearlycheck'>
                                            <input
                                                id='yearlycheck'
                                                className='m-2'
                                                type="checkbox"
                                                checked={state.isYearlyChecked}
                                                onChange={handleYearlyCheckboxChange}
                                            />
                                            <span className='m-2'>I Accept Terms and Conditions</span>
                                        </label>
                                        <center className='mt-2'>
                                            <Button size='larg' color='info' className='button button-info' disabled={!state.isYearlyButton} onClick={() => handleSubscription(selectedDetails, prices.yearlyPrice, state.isMonthalyChecked)}>Annually</Button>
                                        </center>
                                    </div>
                                </div>
                            </div>
                            <Container className=' is-flex is-justify-content-space-around'>
                                <Columns multiline>
                                    <Column size={12} ref={boxRef}>
                                        {state.showStripeDropIn && (
                                            <div>
                                                <center>
                                                    {state.isYearlyChecked && (
                                                        <div className='mt-3'>
                                                            <input
                                                                className='m-2'
                                                                type="checkbox"
                                                                checked={state.saveCard}
                                                                onChange={handleSaveCardInfo}
                                                            />
                                                            <span className='m-2'>Securely store card information</span>
                                                        </div>
                                                    )}
                                                    <br />
                                                    {state.payButton &&
                                                        <StripeCheckout
                                                            token={handleToken}
                                                            stripeKey={publishableKey && publishableKey}
                                                            name={name}
                                                            amount={prices.finalPrice}
                                                            email={userEmail && userEmail}
                                                            label="Pay"
                                                        />
                                                    }
                                                </center>
                                            </div>
                                        )}
                                    </Column>
                                </Columns>
                            </Container>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal> : null}
            <Pricing
                handleGetStarted={handleGetStarted}
                subscription={subscription}
            />
            {state.showWarningPopup ?
                <Modal active={state.showWarningPopup} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                            <Title size='5' className='mb-0'>
                                Warning
                            </Title>
                            <Button onClick={() => handleClosePopup()}>X</Button>
                        </Modal.Card.Head>
                        <Modal.Card.Body>
                            <Columns multiline>
                                <Column size='full'>
                                    <ul className='ml-0 pl-0 has-text-danger' style={{ listStyle: 'none' }}>
                                        <li>
                                            <div className='is-flex'>
                                                <span className='mr-2' role="img" aria-label="checkmark">✓</span>Your check-in rules for multiple entrance will be lost
                                            </div>
                                        </li>
                                        <li>
                                            <div className='is-flex'>
                                                <span className='mr-2' role="img" aria-label="checkmark">✓</span>You will lose the ability to send and receive the messages from customer
                                            </div>
                                        </li>
                                        <li>
                                            <div className='is-flex'>
                                                <span className='mr-2' role="img" aria-label="checkmark">✓</span>You will lose the ability to add custom form and other capibilities based on your plan
                                            </div>
                                        </li>
                                    </ul>
                                </Column>
                                <Column size='full'>Are you sure you want to proceed?</Column>
                                <Column size='6' className='p-2'>
                                    <Button size='larg' className='button is-info mr-2' onClick={() => handleProceed()}>Proceed</Button>
                                    <Button size='larg' className='button' onClick={() => handleClosePopup()}>Cancel</Button>
                                </Column>
                            </Columns>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal> : null}
        </React.Fragment >
    );
};

export default ManageSubscription;
