import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DELETE_BUSINESS_HOLIDAY } from '../requests';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useDeleteBusinessHoliday = (refetch) => {
    const { id } = useParams();
    const stateBusinessId = useSelector(state => state?.business?.businessId);

    const [onBusinessHolidayDelete, { loading: isDeleteLoading, error }] = useMutation(DELETE_BUSINESS_HOLIDAY, {
        onCompleted: ({ deleteBusinessHoliday: { isSaved, errorMessage, }, }) => {
            if (isSaved) {
                toast.success('Holiday Deleted Successfully');
                refetch({ id: id || stateBusinessId }); // fetch business details with id from params, if business is being created than fetch id from global 
            }
            else {
                throw errorMessage || error;
            }
        },
        onError: (error) => {
            throw error;
        }
    });
    return { isDeleteLoading, onBusinessHolidayDelete, };
}; 