import React from 'react'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <nav className="pagination is-centered is-fullwidth" role="navigation" aria-label="pagination">
            <button
                className="pagination-previous"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            <button
                className="pagination-next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
            <ul className="pagination-list">
                {[...Array(totalPages)].map((_, index) => (
                    <li key={index}>
                        <button
                            className={`pagination-link ${currentPage === index + 1 ? 'is-current' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Pagination;