import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import {
  Table,
  Subtitle,
  Button,
  Icon,
  Title,
  Buttons,
} from '@safelyq/bulma-ui-library';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import { STATUS } from 'constants/index';
const StyledRow = styled.tr.attrs()`
  &:hover {
    cursor: pointer;
  }
`;

const StyledList = styled.td.attrs(() => ({
  className: 'py-3 pl-2',
}))`
    vertical-align: middle !important;
}
`;

export const TableView = ({ ...props }) => {
  const {
    isAnySubLevel,
    appointments,
    handleEditModalOpen,
    handleConfirmModalOpen,
    isAssociate
  } = props;

  const [item, setItems] = useState(0);

  const aptPerPage = 10;

  const pagesVisited = item * aptPerPage;

  const pageCount = Math.ceil(appointments.length / aptPerPage);

  const handleChangePage = ({ selected }) => {
    setItems(selected);
  };

  const getUserName = (name, firstName, lastName, email) => {
    if (firstName && !lastName) return firstName;
    if (firstName && lastName) return firstName + ' ' + lastName;
    if (!firstName && !lastName && name) return name;
    if (!firstName && !lastName && !name) return email;
  };

  return (
    <div className='table-container appointment-table-container'>
      <Table fullwidth hoverable>
        <thead>
          <tr>
            <th className='pl-2'>Users</th>
            <th className='pl-2'>Date</th>
            <th className='pl-2'>Time</th>
            <th className='pl-2'>Service</th>
            {isAnySubLevel && <th className='pl-2'>Service Provider</th>}
            <th className='pl-2'>Status</th>
            <th className='pl-2'>Actions</th>
          </tr>
        </thead>
        <tbody className='has-text-black'>
          {
            appointments
              .slice(pagesVisited, pagesVisited + aptPerPage)
              .map(
                (
                  { id, status, service: { name: serviceName }, subBusiness: { name: serviceProviderName }, user: { firstName, lastName, name, email }, startTime, checkedInTime,
                  },
                  index
                ) => (
                  <StyledRow className='py-3' key={`appointments-${index}`}
                    style={{ background: String(status).toUpperCase() === STATUS.CHECKED_IN && moment().isBefore(moment(checkedInTime).add(10, 'minutes')) ? 'rgba(18, 173, 43, 0.4)' : '', }}
                  >
                    <StyledList className='is-flex' onClick={() => handleEditModalOpen(id)}  >
                      <Button color='info' className='icon-button'>
                        {String(getUserName(name, firstName, lastName, email)).substring(0, 1)}
                      </Button>
                      <div className='ml-2'>
                        <Title size='6'>   {getUserName(name, firstName, lastName, email)}   </Title>
                        <Subtitle size='6'>{email}</Subtitle>
                      </div>
                    </StyledList>

                    <StyledList onClick={() => handleEditModalOpen(id)}>
                      <span className='tag'>    <strong>{moment(startTime).format('MM-DD-YYYY')}</strong>   </span>
                    </StyledList>
                    <StyledList onClick={() => handleEditModalOpen(id)}>
                      <span className='tag'>  <strong>{moment(startTime).format('hh:mm A')}</strong>   </span>
                    </StyledList>
                    <StyledList onClick={() => handleEditModalOpen(id)}>
                      {String(serviceName).length <= 15 ? serviceName : String(serviceName).substring(0, 15) + '...'}
                    </StyledList>
                    {isAnySubLevel && (
                      <StyledList onClick={() => handleEditModalOpen(id)}>
                        {String(serviceProviderName).length <= 15 ? serviceProviderName : String(serviceProviderName).substring(0, 15) + '...'}
                      </StyledList>
                    )}
                    <StyledList onClick={() => handleEditModalOpen(id)}>
                      <span className={`tag is-light`} style={{ background: `rgba(${getStatusColor(status)}, 0.1)`, color: `rgb(${getStatusColor(status)})`, }} >
                        {getStatusName(status)}
                      </span>
                    </StyledList>
                    <StyledList>
                      <Buttons>
                        <Button color='info' data-tip='Comments' className='icon-button action-btn' onClick={() => handleEditModalOpen(id)}    >
                          <Icon name='comment' />
                        </Button>

                        <>
                          <div className='dropdown is-hoverable is-right ml-3'>
                            <div className='dropdown-trigger'>
                              <Button className='icon-button action-btn' aria-haspopup='true' aria-controls='actions-dropdown'    >
                                <Icon name='ellipsis-v' color='info' scale='lg' />
                              </Button>
                            </div>
                            <div className='dropdown-menu' id='actions-dropdown' role='menu'     >
                              <div className='dropdown-content'>
                                {isAssociate && (
                                  <div className='dropdown-item' onClick={() => handleEditModalOpen(id)}   >
                                    <Icon name='pencil' color='success' />   Edit/View
                                  </div>
                                )}

                                {(String(status).toUpperCase() === STATUS.BOOKED ||
                                  String(status).toUpperCase() === STATUS.CONFIRMED ||
                                  String(status).toUpperCase() === STATUS.CHECKED_IN ||
                                  String(status).toUpperCase() === STATUS.CHECKED_IN) && (
                                    <div className='dropdown-item' onClick={() => handleConfirmModalOpen(id, STATUS.CHECKED_IN_BY_BUSINESS)}    >
                                      <Icon name='check-square-o' color='info' />   Checked-In
                                    </div>
                                  )}
                                {String(status).toUpperCase() ===
                                  STATUS.CHECKED_IN_BY_BUSINESS && (
                                    <div className='dropdown-item' onClick={() => handleConfirmModalOpen(id, STATUS.SERVING_NOW)}   >
                                      <Icon name='check' color='info' />  Serve Now
                                    </div>
                                  )}
                                {String(status).toUpperCase() === STATUS.BOOKED ||
                                  String(status).toUpperCase() === STATUS.CONFIRMED ||
                                  String(status).toUpperCase() === STATUS.CHECKED_IN ||
                                  String(status).toUpperCase() ===
                                  STATUS.CHECKED_IN_BY_BUSINESS ? (
                                  <div className='dropdown-item' onClick={() => handleConfirmModalOpen(id, STATUS.CANCELLED)}    >
                                    <Icon name='times' color='info' />   Cancel Appointment
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                        <ReactTooltip />
                      </Buttons>
                    </StyledList>
                  </StyledRow>
                )
              )
          }
        </tbody>
      </Table>
      {pageCount > 1 ? (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={pageCount}
          onPageChange={handleChangePage}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
        />
      ) : null}
    </div>
  );
};
