import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Button, Icon, Box } from '@safelyq/bulma-ui-library';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import { STATUS } from 'constants/index';
import ReactPaginate from 'react-paginate';

export const TileView = ({ ...props }) => {
  const {
    appointments,
    isAnySubLevel,
    handleConfirmModalOpen,
    handleEditModalOpen,
    isAssociate
  } = props;

  const getUserName = (name, firstName, lastName, email) => {
    if (firstName && !lastName) return firstName;
    if (firstName && lastName) return firstName + ' ' + lastName;
    if (!firstName && !lastName && name) return name;
    if (!firstName && !lastName && !name) return email;
  };

  const [item, setItems] = useState(0);

  const aptPerPage = 5;

  const pagesVisited = item * aptPerPage;

  const pageCount = Math.ceil(appointments.length / aptPerPage);

  const handleChangePage = ({ selected }) => {
    setItems(selected);
  };

  return (
    <Fragment>
      {
        appointments
          .slice(pagesVisited, pagesVisited + aptPerPage)
          .map(
            (
              {
                id,
                status,
                service: { name: serviceName },
                subBusiness: { name: serviceProviderName },
                user: { firstName, lastName, name, email },
                startTime,
              },
              index
            ) => (
              <Box
                shadow
                style={{
                  background:
                    String(status).toUpperCase() === STATUS.CHECKED_IN
                      ? 'rgba(18, 173, 43, 0.4)'
                      : '',
                }}
              >
                <article className='media' key={`appointments-${index}`}>
                  <div style={{ overflow: "visible" }} className='media-content'>
                    <div className='content'>
                      <p>
                        <strong>
                          {getUserName(name, firstName, lastName, email)}
                        </strong>
                        <span className='is-right'>
                          <span
                            className={`tag is-light`}
                            style={{
                              background: `rgba(${getStatusColor(status)}, 0.1)`,
                              color: `rgb(${getStatusColor(status)})`,
                            }}
                          >
                            {getStatusName(status)}
                          </span>
                        </span>
                        <br />
                        <small>{email}</small>
                        <br />
                        {String(serviceName).length <= 16
                          ? serviceName
                          : String(serviceName).substring(0, 16) + '...'}
                        {isAnySubLevel && (
                          <span>
                            {' '}
                            -{' '}
                            {String(serviceProviderName).length <= 15
                              ? serviceProviderName
                              : String(serviceProviderName).substring(0, 15) + '...'}
                          </span>
                        )}
                      </p>
                    </div>
                    <nav className='level is-mobile'>
                      <div className='level-left'>
                        <a className='level-item'>
                          <span className='tag'>
                            <strong>{moment(startTime).format('hh:mm A')}</strong>
                          </span>
                        </a>
                        <a className='level-item'>
                          <span className='tag'>
                            <strong>{moment(startTime).format('MM-DD-YYYY')}</strong>
                          </span>
                        </a>
                      </div>
                      <div className='level-right'>
                        <a className='level-item'>
                          <div className='dropdown is-hoverable is-right ml-3'>
                            <div className='dropdown-trigger'>
                              <Button
                                className='icon-button action-btn'
                                aria-haspopup='true'
                                aria-controls='actions-dropdown'
                              >
                                <Icon name='ellipsis-v' color='info' scale='lg' />
                              </Button>
                            </div>
                            <div
                              className='dropdown-menu'
                              id='actions-dropdown'
                              role='menu'
                            >
                              <div className='dropdown-content'>
                                {isAssociate && (
                                  <div className='dropdown-item' onClick={() => handleEditModalOpen(id)}    >
                                    <Icon name='pencil' color='success' />   Edit/View
                                  </div>
                                )}

                                {(String(status).toUpperCase() === STATUS.BOOKED ||
                                  String(status).toUpperCase() === STATUS.CONFIRMED ||
                                  String(status).toUpperCase() ===
                                  STATUS.CHECKED_IN ||
                                  String(status).toUpperCase() ===
                                  STATUS.CHECKEDIN) && (
                                    <div
                                      className='dropdown-item'
                                      onClick={() =>
                                        handleConfirmModalOpen(
                                          id,
                                          STATUS.CHECKED_IN_BY_BUSINESS
                                        )
                                      }
                                    >
                                      <Icon name='check-square-o' color='info' />
                                      Checked-In
                                    </div>
                                  )}
                                {String(status).toUpperCase() ===
                                  STATUS.CHECKED_IN_BY_BUSINESS && (
                                    <div
                                      className='dropdown-item'
                                      onClick={() =>
                                        handleConfirmModalOpen(id, STATUS.SERVING_NOW)
                                      }
                                    >
                                      <Icon name='check' color='info' />
                                      Serve Now
                                    </div>
                                  )}
                                {String(status).toUpperCase() === STATUS.BOOKED ||
                                  String(status).toUpperCase() === STATUS.CONFIRMED ||
                                  String(status).toUpperCase() === STATUS.CHECKED_IN ||
                                  String(status).toUpperCase() ===
                                  STATUS.CHECKED_IN_BY_BUSINESS ? (
                                  <div
                                    className='dropdown-item'
                                    onClick={() =>
                                      handleConfirmModalOpen(id, STATUS.CANCELLED)
                                    }
                                  >
                                    <Icon name='times' color='info' />
                                    Cancel Appointment
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </a>
                        <a className='level-item'>
                          <Button
                            color='info'
                            className='icon-button action-btn'
                            onClick={() => handleEditModalOpen(id)}
                          >
                            <Icon name='comment' scale='xs' size='small' />
                          </Button>
                        </a>
                      </div>
                    </nav>
                  </div>
                </article>
              </Box>
            )
          )
      }
      {pageCount > 1 ? (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={pageCount}
          onPageChange={handleChangePage}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
        />
      ) : null}
    </Fragment>
  );
};
