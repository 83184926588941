import React, { useEffect, useState } from 'react'
import { Section, Container, Box, Title, Icon, Modal, Button } from '@safelyq/bulma-ui-library';
import { ConsoleImages } from './components/ConsoleImages';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { useConsoleImages, useSaveConsoleImages } from './hooks';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ManageConsole = () => {
    const [addarr, setAddarr] = useState([0])
    const [consoleImages, setConsoleImages] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [allowRefetch, setAllowRefetch] = useState(false);
    const [consoleDetails, setConsoleDetails] = useState();
    const [initialImages, setInitialImages] = useState();
    const [canCompare, setCanCompare] = useState(false);
    const [uploadImageLoading, setUploadImageLoading] = useState(false);
    const [locallyUploadedImages, setLocallyUploadedImages] = useState([]);
    const [showpop, setShowpop] = useState(false)
    const { id } = useParams();
    const history = useHistory();
    const [fileKey, setFileKey] = useState(0);

    const businessFeatures = useSelector(state => state?.business?.businessFeatures);
    const consoleApplicationAllowed = businessFeatures?.consoleApplicationAllowed;

    // Fetch Console Images - if the console application is allowed
    const { data: consoleData, onConsoleImageDelete, isDeleteLoading, refetch } = useConsoleImages(consoleApplicationAllowed);

    // Save ConsoleImage
    const { onSaveImages, savedLoading } = useSaveConsoleImages();

    const handleAccordianChange = (sa) => {
        setAddarr(sa)
    }

    useEffect(() => {
        // If can compare is false means its first render and nothing changed, fill initial with images
        if (canCompare === false) {
            setInitialImages(consoleImages)
        }
        else {
            // Compare Intial Images and All Images
            compareImages();
        }
    }, [consoleImages, consoleDetails?.intervals, consoleDetails?.useBusinessImages])

    // Show popup that you are not subscribed for this module
    useEffect(() => {
        if (businessFeatures !== null) {
            businessFeatures?.consoleApplicationAllowed === false ? setShowpop(true) : setShowpop(false);
        }
    }, [businessFeatures])

    const compareImages = () => {
        if (consoleImages?.length === 0 && consoleDetails?.intervals === 0 && (consoleDetails?.useBusinessImages !== true && consoleDetails?.useBusinessImages !== false)) {
            return;
        }
        const isEqual = JSON.stringify(consoleImages) === JSON.stringify(initialImages) &&
            consoleDetails.intervals === consoleData?.getBusinessById?.displayInterval &&
            consoleDetails.useBusinessImages === consoleData?.getBusinessById?.displayBusinessPicture;
        isEqual ? setDisableSave(true) : setDisableSave(false);
    }

    // Refetch Images after Save has been clicked
    useEffect(() => {
        if (!savedLoading && allowRefetch) {
            refetch();
            setAllowRefetch(false)
        }
    }, [savedLoading])

    useEffect(() => {
        if (consoleData?.getBusinessById) {
            // Concatinate local pictures with the api returned pictures
            setConsoleImages(locallyUploadedImages.length !== 0 ? consoleData?.getBusinessById?.consoleImages?.concat(locallyUploadedImages) : consoleData?.getBusinessById?.consoleImages)
            setConsoleDetails({
                intervals: consoleData?.getBusinessById?.displayInterval || 0,
                useBusinessImages: consoleData?.getBusinessById?.displayBusinessPicture === false ? false : true
            })
        }
    }, [consoleData?.getBusinessById])

    const handleDeleteImage = (deleteImage) => {
        if (deleteImage.base64String) {
            const updatedImages = consoleImages?.filter((sec) => sec.fileName !== deleteImage.fileName)
            setConsoleImages(updatedImages)

            // If Picture removed without saving then also remove from locallyUploaded State
            const update = locallyUploadedImages.filter((sec) => sec.fileName !== deleteImage.fileName)
            setLocallyUploadedImages(update);

        } else {
            handleDeleteConsoleImage(deleteImage.id)
        }
    }

    const handleDeleteConsoleImage = (pictureId) => {
        setAllowRefetch(true);
        if (!window.confirm('Are you sure that you want to delete this Console Picuter?')) return;
        onConsoleImageDelete({ variables: { businessId: id, pictureId } });
    }

    const handleUploadImages = (uploadImage) => {
        setConsoleImages([...consoleImages, ...uploadImage]);

        // Set Locally uploaded images to maintain the local state
        setLocallyUploadedImages([...locallyUploadedImages, ...uploadImage]);
        // Now can compare because data changed
        setCanCompare(true);
        // Image upload loading false
        setUploadImageLoading(false)
    }

    const consoleImagesProps = {
        handleDeleteImage,
        handleUploadImages,
        consoleImages,
        onSaveImages,
        savedLoading,
        consoleDetails,
        setConsoleDetails,
        isDeleteLoading,
        setAllowRefetch,
        setLocallyUploadedImages,
        disableSave,
        setCanCompare,
        setUploadImageLoading,
        uploadImageLoading,
        setDisableSave,
        fileKey,
        setFileKey,
    }

    // Rederict to appointments or pricing
    const handleUpgrade = () => {
        history.push(`/admin/pricing/${id}`);
    };

    const handleCancel = () => {
        history.push(`/admin/appointments-log/${id}`);
    };

    return (
        <Section className='mainSection'>
            {showpop &&
                <Modal active={showpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Body>
                            <div className=' content'>
                                <p>Upgrade your business subscription to access this operation.</p>
                            </div>
                            <div className='is-flex is-justify-content-space-evenly'>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
                            </div>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            }

            <Container>
                <Box className='mainBox' shadow={true}>
                    {/* <Box className='mainBox' shadow={Boolean(id)}> */}
                    <Title size='6' className='mb-4'>
                        <Icon name='image' />
                        <span className='ml-3'> Manage Console </span>
                    </Title>

                    <Accordion allowZeroExpanded={true} onChange={handleAccordianChange}   >
                        <AccordionItem uuid={1} dangerouslySetExpanded={addarr.includes(1)}>
                            <AccordionItemHeading>
                                {/* <AccordionItemHeading style={{ opacity: organization && activeStep >= 1 ? 1 : 0.5, pointerEvents: organization && activeStep >= 1 ? 'visible' : 'none', }} > */}
                                <AccordionItemButton>Console Images</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel  >
                                <ConsoleImages {...consoleImagesProps} />
                            </AccordionItemPanel>
                        </AccordionItem>


                    </Accordion>
                </Box>
            </Container>
        </Section>
    )
}