import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { useForm, useFormState } from 'react-hook-form';
import { Button } from '@safelyq/bulma-ui-library';
import { FiInfo } from "react-icons/fi";
import { Tooltip, Skeleton } from 'antd';
import { useGetBusinessInfo, useSaveBusinessInfo } from './hooks';
import { toast } from 'react-toastify';
import AddressSuggestionPopup from './AddressSuggestionPopup/AddressSuggestionPopup';
import { emailRegex, webAddressRegex } from '../CreateProfile/constants';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBusinessId } from 'Redux/BusinessSlice/Business';

const PhoneNumberValidator = /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/;

const CreateBusinessProfile = ({ business, categories, countries, toolTip, organization, gotToNextStep, onChangeStep, refetchManageBusiness }) => {
    const { id = 0 } = useParams();

    const [businessId, setbusinessId] = useState(id)
    const [saveLoading, setSaveLoading] = useState(false)
    const [addressPopup, setAddressPopup] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [allAddresses, setAllAddresses] = useState([])
    const [businessData, setBusinessData] = useState({
        name: "",
        categoryName: "",
        city: "",
        state: "",
        timeZone: "",
        zipCode: "",
        email: "",
        phone: "",
        webAddress: "",
        address1: "",
        address2: "",
        description: "",
        safetyTags: [],
        categoryTags: [],
        country: 'United States'
    });

    const [tags, setTags] = useState({ categoryTags: [], safetyTags: [] });

    const { loading, data, refetch } = useGetBusinessInfo(businessId);
    const { onSaveBusiness } = useSaveBusinessInfo()
    const dispatch = useDispatch();

    const { register, control, setValue, handleSubmit: onSubmit, reset, watch, formState: { errors }, } = useForm();
    const { dirtyFields } = useFormState({ control });

    const handleSubmit = (values) => {
        let finalData = {};
        let phone = values.phone;
        finalData = {
            regionId: data.getCountries?.find((country) => country.name === values.country)?.id || 0,
            businessDetails: { ...values, phone, showNotesInput: true },
        };


        onSubmitParent({ ...finalData, });
    };

    const handleTagAdd = (event) => {
        const { target: { name, value }, key, } = event;

        if (key === 'Enter' && value) {
            event.preventDefault();
            if (!watch(name)) setValue(name, [value], { shouldDirty: true });
            else setValue(name, [...watch(name), value], { shouldDirty: true });
            setTags({ ...tags, [name]: '' });
        }
    };

    const handleRemoveTag = ({ name, index }) => {
        setValue(name, watch(name).filter((_, indx) => indx !== index), { shouldDirty: true });
    };

    const handleTagChange = ({ target: { name, value } }) => setTags({ ...tags, [name]: value });

    const handleCloseAddressPopup = () => {
        setAddressPopup(false)
        gotToNextStep([2]);
        onChangeStep(2);

    }

    const handlePhoneNumber = (ph) => {
        setValue('phone', ph, { shouldDirty: true, shouldValidate: true, required: true })
        setPhoneNumber(ph)
    }

    const onSubmitParent = async (Data) => {
        const ddd = { ...Data }

        const PHNumber = ddd.businessDetails.phone
        const isPhoneNumberValid = PhoneNumberValidator.test(PHNumber);
        if (!isPhoneNumberValid) {
            toast.error("Phone number is not valid")
        } else {
            try {
                setSaveLoading(true)
                const response = await onSaveBusiness({ variables: { businessInput: { id: data?.getBusinessById?.id, ...Data, organizationId: parseInt(organization) ?? -1, }, }, })

                if (response.data.saveBusiness.isSaved === false) {
                    const errorMessage = { code: 403, message: response.data.saveBusiness.errorMessage };
                    throw errorMessage;
                }
                else {
                    const businessId = response?.data?.saveBusiness?.business?.id;

                    setbusinessId(businessId);
                    if (response.data.saveBusiness.addressValidationResult.isValidAddress === false) {
                        setAddressPopup(true)
                        setAllAddresses(response.data.saveBusiness.addressValidationResult.googleAddresses)
                    }
                    else {
                        gotToNextStep([2])
                        onChangeStep(2);
                        // await refetch()
                    }
                    // refetch manage business 
                    refetchManageBusiness();
                    toast.success("Business Information Saved Successfully");

                    dispatch(setBusinessId(businessId)); // set to global state as to use when id does not exist in params 
                    // if (id === 0)
                    //     localStorage.setItem('businessId', response.data.saveBusiness.business.id);
                }
                setSaveLoading(false)
            } catch (error) {
                setSaveLoading(false)
                toast.error(error.message)
            }
        }

    };

    // set states if data.getBusinessId is not empty
    useEffect(() => {
        // const countryIs = 'USA';
        if (!isEmpty(data)) {
            if (data.getBusinessById) {

                reset({
                    name: data.getBusinessById.name,
                    categoryName: data.getBusinessById.businessCategory?.name,
                    city: data.getBusinessById.city,
                    // state: 'TX 75094',
                    state: data.getBusinessById.state,
                    timeZone: data.getBusinessById.timeZone,
                    zipCode: data.getBusinessById.zipCode,
                    email: data.getBusinessById.email,
                    phone: data.getBusinessById.phone ?? '+1',
                    webAddress: data.getBusinessById.webAddress,
                    address1: data.getBusinessById.address1,
                    address2: data.getBusinessById.address2,
                    description: data.getBusinessById.description,
                    safetyTags: data.getBusinessById.safetyTags,
                    categoryTags: data.getBusinessById.categoryTags,
                    // country: countryIs === 'USA' ? 'United States' : (data?.getBusinessById.country ?? 'United States')
                    country: data?.getBusinessById.country === 'USA' ? 'United States' : (data?.getBusinessById.country ?? 'United States')
                    // if country is USA than set United States else if no country exists than also set the same
                    // country: data.getBusinessById.country ?? 'United States'
                });
                setBusinessData({
                    name: data.getBusinessById.name,
                    categoryName: data.getBusinessById.businessCategory?.name,
                    city: data.getBusinessById.city,
                    state: data.getBusinessById.state,
                    timeZone: data.getBusinessById.timeZone,
                    zipCode: data.getBusinessById.zipCode,
                    country: data.getBusinessById.country === 'USA' ? 'United States' : data.getBusinessById.country,
                    email: data.getBusinessById.email,
                    phone: data.getBusinessById.phone ?? '+1',
                    webAddress: data.getBusinessById.webAddress,
                    address1: data.getBusinessById.address1,
                    address2: data.getBusinessById.address2,
                    description: data.getBusinessById.description,
                    safetyTags: data.getBusinessById.safetyTags,
                    categoryTags: data.getBusinessById.categoryTags,
                });
            }

        }
    }, [data, reset]);

    useEffect(() => {
        if (Object.keys(dirtyFields).length === 1 && dirtyFields.phone) {
            if (businessData?.phone.slice(1) === phoneNumber) {
                setIsDisable(false)
            }
            else {
                setIsDisable(true)
            }
        }
        else {
            setIsDisable(true)
        }
    }, [watch(data), businessData, dirtyFields, phoneNumber]);

    if (loading) {
        return <Skeleton />
    }
    else {
        const isEmptyCategoryTags = watch('categoryTags')?.length <= 0;

        const isEmptySafetyTags = watch('safetyTags')?.length <= 0;
        return (
            <>
                {addressPopup && <AddressSuggestionPopup isOpen={addressPopup} onClose={handleCloseAddressPopup} allAddresses={allAddresses}
                    refetch={refetch}
                    businessId={data?.getBusinessById?.id} businessData={businessData} organization={organization} />}
                <form onSubmit={onSubmit(handleSubmit)}>
                    <div className='row g-4'>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div> Name <span className='has-text-danger'>*</span>  </div>
                                    <Tooltip title={toolTip?.name}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <input className={`input ${errors.name ? 'is-danger' : ''}`}  {...register('name', { required: true })} />
                                    {errors.name && (<p className='help is-danger'>Name is required</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div>  Category <span className='has-text-danger'>*</span>  </div>
                                    <Tooltip title={toolTip?.category}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <div className={`select is-fullwidth ${errors.categoryName ? 'is-danger' : ''}`}  >
                                        <select {...register('categoryName', { required: true })}>
                                            <option value={''} selected disabled>  Select Category   </option>
                                            {data?.getBusinessCategories?.map((category, index) => (
                                                <option key={`category_${index}`} value={category.name}>  {category.description}  </option>
                                            ))}
                                        </select>
                                        {errors.categoryName ? (<p className='help is-danger'>Category is required</p>) : (<Tooltip title={"Please Contact Safleq"}>  <a href='mailto:info@safelyq.com?subject=Request for new category'><small><i>Can not see your category?</i></small></a></Tooltip>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div>Country <span className='has-text-danger'>*</span></div>
                                    <Tooltip title={toolTip?.country}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <div className={`select is-fullwidth ${errors.country ? 'is-danger' : ''}`}   >
                                        <select onChange={({ target: { value } }) => { reset({ timeZone: '', state: '', city: "" }); }}  {...register('country', { required: true })}   >
                                            <option value="">    Select Country   </option>
                                            {data?.getCountries?.map((country, index) => (<option key={`country-${index}`} value={country.name}>  {country.name}  </option>))}
                                        </select>
                                        {errors.country && (<p className='help is-danger'>Country is required</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div> TimeZone <span className='has-text-danger'>*</span></div>
                                    <Tooltip title={toolTip?.timeZone}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <div className={`select is-fullwidth ${errors.timeZone ? 'is-danger' : ''}`}  >
                                        <select {...register('timeZone', { required: true })}>
                                            <option value="" >  Select Timezone  </option>
                                            {data?.getCountries?.find((country) => {
                                                return country.name === watch('country')
                                                // const selectedCountry = watch('country');
                                                // const countryToFind = selectedCountry === 'USA' ? 'United States' : selectedCountry;
                                                // return countryToFind // if selected country is USA than also return for United States
                                            })
                                                ?.timeZones.map((timeZone) => (
                                                    <option key={`timeZone-${timeZone?.id}`} value={timeZone?.name}   >  {timeZone?.name}   </option>
                                                ))}
                                        </select>
                                        {errors.timeZone && (<p className='help is-danger'>TimeZone is required</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div> Address 1 <span className='has-text-danger'>*</span></div>
                                    <Tooltip title={toolTip?.address1}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <input name='address1' className={`input ${errors.address1 ? 'is-danger' : ''}`}  {...register('address1', { required: true })} />
                                    {errors.address1 && (<p className='help is-danger'>Address 1 is required</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>Address 2</label>
                                <div className='control'>
                                    <input className='input' {...register('address2')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>   City <span className='has-text-danger'>*</span>  </label>
                                <div className='control'>
                                    <input className={`input ${errors.city ? 'is-danger' : ''}`}  {...register('city', { required: true })} />
                                    {errors.city && (<p className='help is-danger'>City is required</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>   State <span className='has-text-danger'>*</span>   </label>
                                <div className='control'>
                                    <div className={`select is-fullwidth ${errors.state ? 'is-danger' : ''}`}  >
                                        <select {...register('state', { required: true })}>
                                            <option value={''} selected disabled>  Select State  </option>
                                            {data?.getCountries?.find((country) => country?.name === watch('country'))?.states.map((state) => (
                                                <option key={`state-${state?.id}`} value={state?.name}  >   {state?.name}  </option>
                                            ))}
                                        </select>
                                        {errors.state && (
                                            <p className='help is-danger'>State is required</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>  Zip Code <span className='has-text-danger'>*</span>  </label>
                                <div className='control'>
                                    <input className={`input ${errors.zipCode ? 'is-danger' : ''}`}  {...register('zipCode', { required: true })} />
                                    {errors.zipCode && (<p className='help is-danger'>ZipCode is required</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>    Phone Number <span className='has-text-danger'>*</span>  </label>
                                <div className='control'>
                                    <PhoneInput inputClass='input' countryCodeEditable={false} country={'us'}
                                        value={watch('phone')}
                                        inputProps={{
                                            required: true,
                                            autoFocus: true
                                        }}
                                        onChange={handlePhoneNumber}
                                    />
                                    {errors.phone && (<p className='help is-danger'>Phone Number is required</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>Email Address</label>
                                <div className='control'>
                                    <input type='email' className={`input ${errors.email ? 'is-danger' : ''}`}   {...register('email', { pattern: { value: emailRegex, message: 'Please enter a valid email address', }, })} />
                                    {errors.email && (<p className='help is-danger'>{errors?.email?.message}</p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label'>Web Address</label>
                                <div className='control'>
                                    <input className='input'   {...register('webAddress', { pattern: { value: webAddressRegex, message: 'Please enter a valid web address (http://*****)', }, })} />
                                    {errors.webAddress && (<p className='help is-danger'>  {errors?.webAddress?.message} </p>)}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div>Category Tag</div>
                                    <Tooltip title={toolTip?.categoryTags}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <input className='input' name='categoryTags' value={tags.categoryTags} onKeyPress={handleTagAdd} onChange={handleTagChange} />
                                    <small>   <i>Enter tag and press enter</i>  </small>
                                    {!isEmptyCategoryTags && (
                                        <div className='field is-grouped is-grouped-multiline mt-2'>
                                            {watch('categoryTags')?.map((category, index) => (
                                                <div className='control has-text-grey' key={`categorytag-${index}`}  >
                                                    <div className='tags has-addons'>
                                                        <span className='tag '>{category}</span>
                                                        <span className='tag is-delete' onClick={() => handleRemoveTag({ name: 'categoryTags', index })}   ></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='field'>
                                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                                    <div>Safety Tags</div>
                                    <Tooltip title={toolTip?.safetyTags}> <FiInfo /> </Tooltip>
                                </label>
                                <div className='control'>
                                    <input name='safetyTags' className='input' value={tags.safetyTags} onKeyPress={handleTagAdd} onChange={handleTagChange} />
                                    <small>  <i>Enter tag and press enter</i>  </small>
                                </div>
                                {!isEmptySafetyTags && (
                                    <div className='field is-grouped is-grouped-multiline mt-2'>
                                        {watch('safetyTags')?.map((safetyTag, index) => (
                                            <div className='control has-text-grey' key={`safetytag-${index}`}  >
                                                <div className='tags has-addons'>
                                                    <span className='tag'>{safetyTag}</span>
                                                    <a className='tag is-delete' onClick={() => handleRemoveTag({ name: 'safetyTags', index })} ></a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='field'>
                                <label className='label'>Description</label>
                                <div className='control'>
                                    <textarea className='textarea' rows={4}  {...register('description')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <Button type='submit' color='info' disabled={isEmpty(dirtyFields) || !isEmpty(errors) || !isDisable} loading={saveLoading}>Save</Button>
                        </div>
                    </div>
                </form>
            </>
        )
    }


}

export default CreateBusinessProfile