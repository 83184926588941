import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Section, Icon } from '@safelyq/bulma-ui-library';
import { NoLogFound } from '../../components';
import { useCampaignsData, useAppointmentsData } from './hooks';
import styled, { css } from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import { TableView } from './components/TableViewCampaings';
import { useParams, useHistory } from 'react-router-dom';
import { NoCampaignsFound } from 'features/admin-campaign-feature/src/routes/ManageCampaign/components';
import { TableViewAppontments } from './components/OnlyAppointmentsTable';
import { Button } from '@safelyq/bulma-ui-library';
import firestore from '@firebase';
import { setActiveItem } from 'Redux/MiscSlice/Misc';

export const ManageLogs = React.memo(({ selectedDate }) => {
  const { businessId } = useParams();
  const history = useHistory();
  const businessFeaturesGlobal = useSelector(state => state?.business?.businessFeatures);
  const messageCountGlobal = useSelector((state) => state?.business?.messageCount);
  const notificationCountGlobal = useSelector((state) => state?.business?.notificationCount);
  const reviewsCountGlobal = useSelector((state) => state?.business?.reviewCount);

  const dispatch = useDispatch();
  // Campaign Api
  const { isLoadingCampaign, campaigns, getUserCampaigns } = useCampaignsData();

  // state open/close for the appointments and campaings
  const [tableShow, setTableShow] = useState({
    appointments: false,
    campaings: false
  })

  // Appointments API
  const { getUserAppointments,
    isLoadingAppointments,
    appointments,
  } = useAppointmentsData();

  const [campaignVariables, setCampaignVariables] = useState({
    businessId: businessId,
    searchText: '',
    statuses: ['ACTIVE', 'IN_COMPLETE'],
    skip: 0,
    take: 1000,
    endDate: null,
    startDate: null,
  })

  // Get user campaigns for the first time only
  useEffect(() => {
    !campaigns && getUserCampaigns({
      variables: {
        selectCampaignsInput: campaignVariables
      }
    })

    // close the drawer
    dispatch(setActiveItem(null));
  }, [])

  // Call appointments
  // Snapshot for appointments
  useEffect(() => {
    const unsubscribe = firestore
      .collection("appointments")
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        getUserAppointments({
          variables: {
            businessId,
            id: businessId,
            startDate: moment().format('YYYY-MM-DD'),
            orderSequence: 'DESCENDING',
            sortOrder: 'CREATED_DATE',
            endDate: moment().format('YYYY-MM-DD'),

          },
        })
      });
    return () => unsubscribe();
  }, [businessId]);

  const noAppointmentsFound = appointments?.length === 0 || appointments?.filter(appointment => {
    // Convert startDate to the timezone of the business and return only remaining appointments
    const startDateTimeZone = moment.tz(appointment?.startTime, appointment?.timeZone);
    return startDateTimeZone.isAfter(moment());
  })?.length === 0;

  // Open close the state of the tables
  const handleIconClick = (tableName) => {
    setTableShow(prevState => ({
      ...prevState,
      [tableName]: !prevState[tableName]
    }));
  };

  // if (isErrorAppointments) return <small>Something went wrong</small>;

  // if (noSummaryFound) return <NoLogFound />;

  return (
    <Section>
      <div multiline className='columns is-flex is-justify-content-center'>
        <div className='column p-1 mb-3 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>

          {/* First Row */}
          <div className='columns is-multiline is-flex-mobile is-justify-content-center'>
            <div className='column is-one-third is-half-mobile'>
              <DashboardCard
                hoverEffect
                onClick={() => !businessFeaturesGlobal?.genericNumberMessagingAllowed ? history.push(`/admin/pricing/${businessId}`) : history.push(`/admin/messages/${businessId}`)}
                className='is-clickable'
                style={{
                  opacity: businessFeaturesGlobal?.genericNumberMessagingAllowed ? 1 : 0.8,
                  backgroundColor: businessFeaturesGlobal?.genericNumberMessagingAllowed ? "white" : "#e5e5e5"
                }}>
                <div className='has-text-centered'>
                  <div className='is-flex justify-content-center'>
                    <Icon name='comments' />
                    <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold'>Unread Messages</p>
                  </div>
                  {
                    businessFeaturesGlobal?.genericNumberMessagingAllowed
                      ?
                      <b
                        className={`is-size-1 is-size-2-mobile 
                         ${businessFeaturesGlobal?.genericNumberMessagingAllowed ? "is-clickable" : "has-text-black"} 
                        ${messageCountGlobal == 0 ? "has-text-danger" : "has-text-info"}`}
                        onClick={() => businessFeaturesGlobal?.genericNumberMessagingAllowed && history.push(`/admin/messages/${businessId}`)}
                      >
                        {messageCountGlobal || 0}</b>
                      :
                      <b className={`is-size-1 is-size-2-mobile has-text-grey`}>0</b>
                  }

                </div>
              </DashboardCard>
            </div>

            <div className='column is-one-third- is-half-mobile'>
              <DashboardCard hoverEffect
                onClick={() => history.push(`/admin/notifications/${businessId}`)}
              >
                <div className='has-text-centered'>
                  <div className='is-flex justify-content-center'>
                    <Icon name='bell' />
                    <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold'>Unviewed Notifications</p>
                  </div>
                  <b className={`is-size-1 is-size-2-mobile is-clickable ${notificationCountGlobal == 0 ? "has-text-danger" : "has-text-info"}`}>{notificationCountGlobal || 0}</b>
                </div>
              </DashboardCard>
            </div>

            <div className='column is-one-third is-half-mobile'>
              <DashboardCard hoverEffect
                onClick={() => history.push(`/admin/manage-reviews/${businessId}`)}
              >
                <div className='has-text-centered'>
                  <div className='is-flex justify-content-center'>
                    <Icon name='star' />
                    <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold'>Unread Reviews</p>
                  </div>
                  <b className={`is-size-1 is-size-2-mobile is-clickable ${reviewsCountGlobal === 0 ? "has-text-danger" : "has-text-info"}`}> {reviewsCountGlobal || 0}</b>
                </div>
              </DashboardCard>
            </div>
          </div>

          {/* Second row */}
          <div className='columns is-multiline is-flex is-justify-content-around bg-dark-gray ml-1'>
            {/*Appointments */}
            <BorderedLayoutSides className='column is-half-desktop is-full-mobile'>
              <DashboardCard hoverEffect
                onClick={() => history.push(`/admin/appointments/${businessId}`)}
              >
                <div className='is-flex is-justify-content-space-around' style={{ backgroundColor: "white" }}>
                  {/* Total Appointments */}
                  <div className='has-text-centered'>
                    <div className='is-flex justify-content-center'>
                      <Icon name='calendar' />
                      <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold is-flex'> <span>Today's Total <br /> Appointments</span>
                      </p>
                    </div>
                    <b
                      className={`is-size-1 is-size-2-mobile is-clickable ${(!appointments || appointments.length === 0)
                        ? 'has-text-danger'
                        : 'has-text-info'
                        }`}
                    >
                      {appointments?.length || 0}
                    </b>
                  </div>

                  {/* Separator */}
                  <div style={{
                    width: "1px",
                    backgroundColor: "#ccc",
                    margin: "0 20px"
                  }} className=''></div>

                  {/* Remaining Appointments */}
                  <div className='has-text-centered'>
                    <div className='is-flex justify-content-center'>
                      <Icon name='calendar' />
                      <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold is-flex'> <span>Today's Remaining <br /> Appointments</span>
                      </p>
                    </div>
                    <b
                      className={`is-size-1 is-size-2-mobile is-clickable 
                          ${(!appointments || appointments?.filter(appointment => {
                        const startDateTimeZone = moment.tz(appointment?.startTime, appointment?.timeZone);
                        return startDateTimeZone.isAfter(moment());
                      })?.length === 0)
                          ? 'has-text-danger'
                          : 'has-text-info'
                        }`}
                    >
                      {appointments?.filter(appointment => {
                        // Convert startDate to the timezone of the business
                        const startDateTimeZone = moment.tz(appointment?.startTime, appointment?.timeZone);
                        return startDateTimeZone.isAfter(moment());
                      })?.length || 0}
                    </b>
                  </div>
                </div>

                {/* Appointments Details Button */}
                <div className='is-hidden-tablet is-fullwidth is-flex is-justify-content-center mt-2'>
                  <Button style={{
                    paddingLeft: "8px", paddingRight: "8px", height: "24px",
                  }}
                    className="has-text-black is-clickable" onClick={() => handleIconClick("appointments")}>
                    <small> Details </small>
                    <Icon style={{ fontSize: "10px", paddingTop: "2px", color: "black" }} name={tableShow.appointments ? "arrow-up" : "arrow-down"} />
                  </Button>
                </div>

              </DashboardCard>
            </BorderedLayoutSides>

            {/* Active Campaigns Count Card - hide campaings in mobile */}
            <BorderedLayoutSides className='column is-half-desktop is-hidden-mobile'>
              <div className='columns'>
                <div className='column is-full-desktop is-full-mobile'>
                  <DashboardCard hoverEffect
                    onClick={() => history.push(`/admin/view-campaign/${businessId}`)}
                  >
                    <div className='has-text-centered'>
                      <div className='is-flex justify-content-center'>
                        <Icon name='bullhorn' />
                        <p className='is-size-6 ml-2 has-text-weight-semibold is-flex'>
                          <span> Active <br /> Campaigns </span>
                        </p>
                      </div>
                      <b
                        className={`is-size-1 is-size-2-mobile is-clickable ${campaigns?.filter(campaign => campaign.status === 'Active')?.length === 0
                          ? 'has-text-danger'
                          : 'has-text-info'
                          }`}
                      >
                        {campaigns?.filter(campaign => campaign.status === 'Active')?.length || 0}
                      </b>
                    </div>
                  </DashboardCard>
                </div>
                <div className='column is-hidden-touch'></div>
              </div>
            </BorderedLayoutSides>
          </div>

          {/* Appointment and campaign tables */}
          <div className='columns is-multiline ml-1'>
            {/* Appointments table for desktop only */}
            <BorderedLayoutBottom className='column is-half-widescreen is-half-fullhd is-full-desktop is-full-tablet is-hidden-mobile'>
              <DashboardCard style={{ height: "480px" }}>
                <div className='is-flex is-justify-content-space-between'>
                  <div className='is-flex'>
                    <Icon name='calendar' className="px-2" />
                    <p className='is-size-6 has-text-weight-bold is-clickable' onClick={() => history.push(`/admin/appointments/${businessId}`)}>Todays's Remaining Appointments</p>
                  </div>
                </div>
                {

                  isLoadingAppointments
                    ?
                    <div className='is-flex is-justify-content-center is-align-items-center mt-5'>
                      <ClipLoader size={40} />
                    </div>
                    :
                    noAppointmentsFound
                      ?
                      <NoLogFound />
                      :
                      appointments && appointments?.length !== 0 && <TableViewAppontments
                        appointments={appointments}
                        //  isRefetchAppointments={isRefetchAppointments} 
                        getUserAppointments={getUserAppointments} />
                }
              </DashboardCard>
            </BorderedLayoutBottom>

            {/* Appointments table for mobile only */}
            {
              tableShow.appointments && <div className='column is-half-widescreen is-half-fullhd is-full-desktop is-full-tablet is-full-mobile'>
                <DashboardCard style={{ height: "480px" }}>
                  <div className='is-flex is-justify-content-space-between'>
                    <div className='is-flex'>
                      <Icon name='calendar' className="px-2" />
                      <p className='is-size-6 has-text-weight-bold is-clickable' onClick={() => history.push(`/admin/appointments/${businessId}`)}>Todays's Remaining Appointments</p>
                    </div>
                  </div>
                  {
                    isLoadingAppointments
                      ?
                      <div className='is-flex is-justify-content-center is-align-items-center'>
                        <ClipLoader size={40} />
                      </div>
                      :
                      noAppointmentsFound
                        ?
                        <NoLogFound />
                        :
                        appointments && appointments?.length !== 0 && <TableViewAppontments appointments={appointments} />
                  }
                </DashboardCard>
              </div>
            }

            {/* Campaign card only for mobile */}
            <div className='column is-half-desktop is-half-mobile is-hidden-tablet'>
              <div className='columns is-mobile'>
                <div className='column is-half-desktop is-full-mobile'>
                  <DashboardCard hoverEffect>
                    <div className='has-text-centered'>
                      <div className='is-flex justify-content-center'>
                        <Icon name='bullhorn' />
                        <p className='is-size-6 is-size-7-mobile ml-2 has-text-weight-semibold is-flex'>
                          <span> Active <br /> Campaigns </span>
                          {/* <Icon name={tableShow.campaings ? "arrow-up" : "arrow-down"} className="is-hidden-fullhd ml-2 has-text-grey is-clickable" onClick={() => handleIconClick("campaings")} /> */}
                        </p>
                      </div>
                      <b
                        className={`is-size-1 is-size-2-mobile is-clickable ${campaigns?.filter(campaign => campaign.status === 'Active')?.length === 0
                          ? 'has-text-danger'
                          : 'has-text-info'
                          }`}
                        onClick={() => history.push(`/admin/view-campaign/${businessId}`)}
                      >
                        {campaigns?.filter(campaign => campaign.status === 'Active')?.length || 0}
                      </b>

                      {/* Campaings Details Button */}
                      <div className='is-fullwidth is-flex is-justify-content-center mt-2'>
                        <Button style={{
                          paddingLeft: "8px", paddingRight: "8px", height: "24px",
                        }}
                          className="is-hidden-tablet has-text-black is-clickable" onClick={() => handleIconClick("campaings")}>
                          <small> Details </small>
                          <Icon style={{ fontSize: "10px", paddingTop: "2px", color: "black" }} name={tableShow.campaings ? "arrow-up" : "arrow-down"} />
                        </Button>
                      </div>

                    </div>
                  </DashboardCard>
                </div>
                <div className='column is-hidden-touch'></div>
              </div>
            </div>

            {/* Campaign Table for desktop only */}
            <BorderedLayoutBottom className='column is-half-widescreen is-half-fullhd is-full-desktop is-full-tablet is-hidden-mobile'>
              <DashboardCard style={{ height: "480px" }} >
                <div className='is-flex is-justify-content-space-between'>
                  <div className='is-flex'>
                    <Icon name='bullhorn' className="px-2" />
                    <p className='is-size-6 has-text-weight-bold is-clickable' onClick={() => history.push(`/admin/view-campaign/${businessId}`)}>Campaigns</p>
                  </div>
                </div>

                {
                  isLoadingCampaign
                    ?
                    <div className='is-flex is-justify-content-center is-align-items-center mt-5'>
                      <ClipLoader size={40} />
                    </div>
                    :

                    !campaigns || campaigns.length === 0
                      ?
                      <NoCampaignsFound />
                      :
                      campaigns && <TableView campaigns={campaigns} />
                }
              </DashboardCard>
            </BorderedLayoutBottom>

            {/* Campaign Table for mobile only */}
            {
              tableShow.campaings && <div className='column is-half-widescreen is-half-fullhd is-full-desktop is-full-tablet is-full-mobile'>
                <DashboardCard style={{ height: "480px" }} >
                  <div className='is-flex is-justify-content-space-between'>
                    <div className='is-flex'>
                      <Icon name='bullhorn' className="px-2" />
                      <p className='is-size-6 has-text-weight-bold is-clickable' onClick={() => history.push(`/admin/view-campaign/${businessId}`)}>Campaigns</p>
                    </div>
                  </div>

                  {
                    isLoadingCampaign
                      ?
                      <div className='is-flex is-justify-content-center is-align-items-center'>
                        <ClipLoader size={40} />
                      </div>
                      :

                      !campaigns || campaigns.length === 0
                        ?
                        <NoCampaignsFound />
                        :
                        campaigns && <TableView campaigns={campaigns} />
                  }
                </DashboardCard>
              </div>
            }

          </div>
        </div>
      </div>
    </Section>
  );
});

const hoverStyles = css`
  transform: scale(1.03);
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const DashboardCard = styled.div`
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  position: relative; /* Ensure the card is the reference point for absolute positioning */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  ${(props) => props.hoverEffect && css`
    &:hover {
      ${hoverStyles}
    }
  `}
`;

const BorderedLayoutSides = styled.div`
 border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5; 
  border-top: 2px solid #e5e5e5;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  width: 49% !important;
  margin-right: 10px;

  @media (max-width: 2800px) { /* Tablet and lower */
  width: 49% !important;
  margin-right: 10px;
  }

   @media (max-width: 1526px) { /* Tablet and lower */
    width: 48% !important;
    margin-right: 10px;
  }

    @media (max-width: 900px) { /* Tablet and lower */
  width: 100% !important;
  }

    @media (max-width: 1215px) { /* Tablet and lower */
    border: 0px;
  }
`;

const BorderedLayoutBottom = styled.div`
 border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5; 
  border-bottom: 2px solid #e5e5e5; 
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  @media (max-width: 2800px) { /* Tablet and lower */
  width: 49% !important;
  margin-right: 10px;
  }

    @media (max-width: 1526px) { /* Tablet and lower */
    width: 48% !important;
    margin-right: 10px;
  }

   @media (max-width: 900px) { /* Tablet and lower */
  width: 100% !important;
  }

   @media (max-width: 1215px) { /* Tablet and lower */
    border: 0px;
  }
`;