import React from 'react';
import { Container, Title, Subtitle } from '@safelyq/bulma-ui-library';

export const NoLogFound = () => (
    // <section className='hero is-fullheight'>
    <section className='hero'>
        <div className='hero-body'>
            <Container className='has-text-centered'>
                <img src='/images/search-not-found.svg' className='mb-2' width={130} />
                <Title size='5'>No appointments log found</Title>
                <Subtitle size='6'>
                    As appointments are set, they will appear here
                </Subtitle>
            </Container>
        </div>
    </section>
);
