import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import {
  Field,
  Control,
  Icon,
  TextInput,
  Button,
  Modal
} from '@safelyq/bulma-ui-library';
import { useMessageState, useMessageDispatch } from '../../index';
import { setMessageCount } from 'Redux/BusinessSlice/Business';
import { useDispatch, useSelector } from 'react-redux';
import { useData } from './hooks';

const ScrollList = styled.ul.attrs(() => ({}))`
  height: calc(100vh - 8rem);
  overflow: scroll;
  background: white;
`;

const EllipsisText = styled.p.attrs(() => ({}))`
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const Tag = styled.p.attrs(() => ({
  className: 'tag is-right is-danger has-font-weight-bold',
}))`
  width: 2em;
  height: 2em;
  border-radius: 100% !important;
  background: #06d755 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 25px;
`;

const ListItem = styled.li.attrs(() => ({
  className: 'p-3',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: ${({ bgGrey, active }) =>
    bgGrey || active ? 'rgba(0,0,0,0.04)' : 'white'};
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }
`;

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  border: 0;
  border-radius: 20px;
`;

export const RecentConversation = () => {
  const { businessId } = useParams();
  const history = useHistory();
  const dispatchRTK = useDispatch();
  const genericNumberMessagingAllowed = useSelector(state => state?.business?.businessFeatures?.genericNumberMessagingAllowed);

  // Api call for canBusinessOptIn, isGoogleReserve, isBusinessAlreadyOptIn
  // const { isLoading: loading, data } = useQuery(GET_BUSINESS_BY_ID, {
  //   variables: { id: businessId },
  //   skip: !genericNumberMessagingAllowed, // skip the api if the generic number messaging is not allowed 
  //   fetchPolicy: 'no-cache',
  // });

  // Api call for fetching messages
  const { isLoading } = useData(genericNumberMessagingAllowed);

  const dispatch = useMessageDispatch();

  const { recentConversation, selectedUserId } = useMessageState();

  const [showpop, isShowPopup] = useState(false)

  const handleUserSelect = (id) =>
    dispatch({ type: 'SET_SELECTED_USER', payload: { id } });

  const handleFilter = ({ target: { value: searchText } }) =>
    dispatch({ type: 'FILTER_RECENT_MESSAGES', payload: searchText });

  const handleIsOpen = () => {
    if (genericNumberMessagingAllowed) {
      dispatch({ type: 'SET_NEW_MESSAGE_OPEN', payload: { isOpen: true } });
    }
    else {
      isShowPopup(true)
    }
  }

  const handleUpgrade = () => {
    history.push(`/admin/pricing/${businessId}`);
  };

  const handleCancel = () => {
    isShowPopup(false);
  };

  const getName = (firstName, lastName, name, phoneNumber) => {
    let fullName = '';
    if (firstName && lastName) fullName = firstName + ' ' + lastName;
    else if (name) fullName = name;
    else fullName = phoneNumber;
    if (fullName?.length > 13) return fullName.substring(0, 12) + '...';
    else return fullName;
  };


  // set total unread count to global state (RTK) from contextAPI and update everywhere
  useEffect(() => {
    if (recentConversation.length !== 0) {
      let totalCount = 0;
      recentConversation.forEach(conversation => {
        totalCount += conversation.unreadCount;
      })
      dispatchRTK(setMessageCount(totalCount));
    }

  }, [recentConversation])

  // if (isLoading || loading) return <Loader isFullScreen />;
  // else
  return (
    <>
      {showpop &&
        <Modal active={showpop} clipped>
          <Modal.Background />
          <Modal.Card>
            <Modal.Card.Body>
              <div className=' content'>
                <p>Upgrade your business subscription to access this operation.</p>
              </div>
              <div className='is-flex is-justify-content-space-evenly'>
                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
              </div>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal>
      }
      <Fragment>
        <ul>
          <ListItem className='p-0 is-flex is-align-items-center' bgGrey>
            <div style={{ width: '100%' }}>
              <Field>
                <Control>
                  <Icon name='search' position='left' />
                  <StyledSearchBar
                    placeholder={'Search chat'}
                    onChange={handleFilter}
                  />
                </Control>
              </Field>
            </div>
            <Button
              size='small'
              color='info'
              rounded
              className='ml-2'
              onClick={handleIsOpen}
            >
              <Icon name='plus' />
            </Button>
          </ListItem>
        </ul>
        <ScrollList>
          {isEmpty(recentConversation) ? (
            <div className='hero is-medium'>
              <div className='hero-body is-justify-content-center'>
                <center>
                  <span className='has-text-grey-light'>
                    No recent conversation
                  </span>
                  <br />
                  <Link
                    to='#'
                    onClick={(e) => {
                      e.preventDefault();
                      handleIsOpen();
                    }}
                  >
                    Start conversation
                  </Link>
                </center>
              </div>
            </div>
          ) : (
            recentConversation.map(
              ({
                user: { id, name, firstName, lastName, phoneNumber },
                unreadCount,
                recentMessages,
                recentMessageTime,
              }) => (
                <ListItem
                  active={id === selectedUserId}
                  onClick={() => handleUserSelect(id)}
                >
                  <article className='media'>
                    <figure className='media-left'>
                      <p className='image is-48x48 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                        {getName(firstName, lastName, name, phoneNumber)
                          .toUpperCase()
                          .charAt(0)}
                      </p>
                    </figure>
                    <div className='media-content'>
                      <div className='content is-relative'>
                        <div>
                          <span>
                            <strong>
                              {getName(firstName, lastName, name, phoneNumber)}
                            </strong>
                          </span>
                          <span className='is-right is-size-7 has-text-grey-light'>
                            {moment.utc(recentMessageTime).local().calendar()}{' '}
                          </span>
                        </div>
                        <p className='m-0'>
                          <EllipsisText>
                            {recentMessages[0].message.commentText}
                          </EllipsisText>
                        </p>
                        {unreadCount > 0 && (
                          <Tag>{unreadCount > 9 ? '9+' : unreadCount}</Tag>
                        )}
                      </div>
                    </div>
                  </article>
                </ListItem>
              )
            )
          )}
        </ScrollList>
      </Fragment>
    </>
  );
};
