import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { isEqual, isEmpty } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import Linkify from 'linkify-react';
import EmojiPicker from 'emoji-picker-react';
import { ClipLoader } from 'react-spinners';
import {
  TextInput,
  Text,
  Icon,
  Button,
  Title,
  Subtitle,
  Modal
} from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { AttachmentsPanel } from '../AttachmentsPanel';
import { useProfile } from 'hooks/useProfile';
import { useData, useFile } from './hooks';
import { useMessageState, useMessageDispatch } from '../../index';
import logo from 'assets/logo.png'
import FormsDropZone from './FormsDropZone';
import axios from 'axios';
import { useSelector } from 'react-redux';

const IconStyled = styled(Icon)`
  width: 1rem
`;

const LogoStyled = styled.img`
height: 1rem; 
width: 1rem;
filter: grayscale(1)
`;

const ScrollList = styled.ul.attrs(() => ({}))`
  overflow: scroll;
  height: calc(97vh - 100px);
  background: #e5ddd5;
  padding-bottom: 73px;
  padding-top: 10px;
  display: flex;
  flex-direction: column-reverse;
`;

const HeroSection = styled.div.attrs(() => ({}))`
  max-height: calc(100vh - 3.28rem);
  overflow: scroll;
  position: relative;
`;

const TopBar = styled.div.attrs(() => ({
  className: 'p-4',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListItem = styled.li.attrs(() => ({
  className: 'p-2 py-3',
}))`
  display: flex;
  flex-direction: ${({ sender }) => (sender ? 'row-reverse' : 'row')};
}
`;

const RecipientBadge = styled.div.attrs(() => ({
  className: 'p-1 px-3',
}))`
  background: #ffffff;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

const CouponBadge = styled.div.attrs(() => ({}))`
  background: #ffffff;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  padding: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: #ffffff;
    min-width: 300px;
    border-radius: 10px;
    color: black;
  }
`;

const SenderBadge = styled.div.attrs(() => ({
  className: 'p-1 px-3  has-background-primary-light',
}))`
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

const SendInputSection = styled.div.attrs(() => ({
  className: 'p-3 is-flex is-align-items-center',
}))`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ededed;
`;

const MessageField = styled(TextInput).attrs(() => ({
  className: 'px-3',
}))`
  border: 0;
  border-radius: 20px;
`;

const SendButton = styled(Icon).attrs(() => ({
  className: 'has-text-grey text-left',
}))`
  position: relative;
  left: -30px;
  &:hover {
    cursor: pointer;
    color: blue !important;
    &::before {
      content: 'Send';
      position: absolute;
      left: 50%;
      bottom: calc(100% + 9px);
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 5px;
      background-color: #333; 
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.8;
      z-index: 1;
    }
  }
`;

const AttachmentButton = styled.div`
  & > label {
    &:hover {
      cursor: pointer;
      color: blue !important;
    }
  }
   &:hover {
   &::before {
      content: 'Attachment';
      position: absolute;
      left: 94%;
      bottom: calc(100% - 12px);
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 5px;
      background-color: #333; 
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.8;
      z-index: 1;
    }
  }
`;

const PromotionButton = styled.div`
  &:hover {
    cursor: pointer;
    color: blue !important;
    &::before {
      content: attr(data-hover-text);
      position: absolute;
      left: 90%;
      bottom: calc(100% - 12px);
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 5px;
      background-color: #333;
      color: #fff; 
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.8;
      z-index: 1;
    }
  }
`;

const StyledContent = styled.div.attrs(() => ({
  className: 'content',
}))`
  line-height: 17px;
`;

const StyledDropzone = styled.div.attrs(() => ({}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
`;

const StyledDropBox = styled.div`
  border: 5px dashed #ededed;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: lightgrey;
  padding: 10px;
`;

const StyledDropImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledIcon = styled(Icon)`
  font-size: 50px;
`;

export const MessageSection = () => {
  const history = useHistory();
  const {
    onSendMessageLoading,
    onSendMessageToUser,
    onGetUserConversation,
  } = useData();

  const { onUpload, onRemove, ...fileParams } = useFile();

  const { businessId } = useParams();
  const businessCategoryGlobal = useSelector(state => state?.business?.businessCategory);

  const dispatch = useMessageDispatch();

  const {
    profile: { sub },
  } = useProfile();

  let {
    selectedUserId,
    isReceivedSignal,
    userConversation,
    isSuccess,
    user: { name: userName, email, phoneNumber } = {},
    business: {
      businessCoupons,
      businessGoogleForms,
      messaging: {
        messagingAllowed,
        consumerInitiationAllowed,
      } = {},
    } = {},
  } = useMessageState();


  // const { isDataLoading, data, } = useGetBusinessById(parseInt(businessId));
  const genericNumberMessagingAllowed = useSelector(state => state?.business?.businessFeatures?.genericNumberMessagingAllowed);

  const [messageText, setMessageText] = useState('');

  const [isOpenEmojiModal, setOpenEmojiModal] = useState(false);

  const [isOpenAttachmentDropZone, setOpenAttachmentDropZone] = useState(false);

  const [isOpenPromotionDropZone, setOpenPromotionDropZone] = useState(false);
  const [isOpenFormDropZone, setOpenFormDropZone] = useState(false);
  const [showpop, isShowPopup] = useState(false)

  const [isOpenAttachments, setOpenAttachmentsModal] = useState(false);

  const [messageSend, setMessageSend] = useState(false);

  const refetch = new CustomEvent("refetch");

  document.dispatchEvent(refetch);

  let element = document.getElementById("scroll-list");
  // let genericNumberMessagingAllowed = data?.getBusinessById?.businessSubscription?.subscriptionPlan?.features?.genericNumberMessagingAllowed;

  const { scrollHeight } = element || {}

  useEffect(() => {
    if (element) element.scrollTop = scrollHeight
  }, [selectedUserId, scrollHeight]);

  useEffect(() => {
    const userId = selectedUserId;

    if (userId) onGetUserConversation({ variables: { userId, businessId } });
  }, [selectedUserId, messageSend]);

  useEffect(() => {
    const userId = selectedUserId;

    if (isReceivedSignal && userId)
      onGetUserConversation({ variables: { userId, businessId } });
  }, [isReceivedSignal]);

  const isRecipient = (userId) => !isEqual(sub, userId);

  const handleMessageTextChange = ({ target: { value } }) =>
    setMessageText(value);

  const handleEmojiChange = (_, emojiObject) =>
    setMessageText(messageText + emojiObject.emoji);

  const handleEmojiModalOpen = () => setOpenEmojiModal(!isOpenEmojiModal);

  const handleMessageSend = () => {
    let attachment = fileParams.path
      ? {
        fileName: fileParams.name,
        base64String: fileParams.path,
        contentType: fileParams.type,
      }
      : null;

    if (!messageText && !attachment) return;

    const isValidMessage =
      messageText ||
      String(fileParams.type).includes('image') ||
      String(fileParams.type).includes('video') ||
      String(fileParams.type).includes('audio') ||
      String(fileParams.type).includes('pdf') ||
      String(fileParams.type).includes('word') ||
      String(fileParams.type).includes('spreadsheet') ||
      String(fileParams.type).includes('presentation') ||
      String(fileParams.type).includes('plain') ||
      String(fileParams.type).includes('application');

    if (!isValidMessage) {
      alert('File type not supported');
      setMessageText('');
      onRemove();
      setOpenAttachmentDropZone(false);
      return;
    }

    let variables = {
      userMessageInput: {
        businessId: parseInt(businessId),
        userId: selectedUserId,
        messageText,
        attachment,
      },
    };

    if (!attachment) delete variables.attachment;

    onSendMessageToUser({
      variables,
    }).then(() => {
      setMessageText('');
      onRemove();
      setOpenAttachmentDropZone(false);
      if (attachment) {
        // Call get user conversation
        setMessageSend(!messageSend);
      }
    });
  };

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') handleMessageSend();
  };

  const handleBack = () => {
    dispatch({ type: 'SET_SELECTED_USER', payload: { id: null } });
  };

  const handleAttachmentDropZoneOpen = () =>
    setOpenAttachmentDropZone(!isOpenAttachmentDropZone);

  const handlePromotionDropZoneOpen = () => {
    // onGetBusiness({ variables: { businessId: selectedBusinessId } });
    setOpenPromotionDropZone(!isOpenPromotionDropZone);
  };
  const handleFormsDropZoneOpen = () => {
    setOpenFormDropZone(true);
  };

  const onRemoveFormsDropZone = () => {
    setOpenFormDropZone(false);
  }

  const handleOpenNewMessageModal = (e) => {
    e.preventDefault();
    if (genericNumberMessagingAllowed) {
      dispatch({ type: 'SET_NEW_MESSAGE_OPEN', payload: { isOpen: true } });
    }
    else {
      isShowPopup(true);
    }
  };

  const handleCouponSend = (coupon) => {
    let attachment = { url: coupon?.image };

    if (!messageText && !attachment) return;

    let variables = {
      userMessageInput: {
        businessId: parseInt(businessId),
        userId: selectedUserId,
        businessCouponId: coupon?.id,
        messageText: coupon?.title,
        messageType: 'BUSINESS_COUPON',
        attachment,
      },
    };

    if (!attachment) delete variables.attachment;

    onSendMessageToUser({
      variables,
    }).then(() => {
      setMessageText('');
      onRemove();
      setOpenPromotionDropZone(false);

      // Call get user conversation
      setMessageSend(!messageSend);
    });
  };


  const handleFormSend = async (form, title) => {
    try {
      const response = await axios.get(`https://tinyurl.com/api-create.php?url=${form}`,);
      const shortUrl = response.data
      let variables = {
        userMessageInput: {
          businessId: parseInt(businessId),
          userId: selectedUserId,
          // messageText: shortUrl + "_" + title
          messageText: `Form - ${title}: ${shortUrl}`
        },
      };
      onSendMessageToUser({
        variables,
      }).then(() => {
        setMessageText('');
        setOpenFormDropZone(false);
        setMessageSend(!messageSend);
      });
    } catch (error) {
    }
  }


  const handleUpgrade = () => {
    history.push(`/admin/pricing/${businessId}`);
  };

  const handleCancel = () => {
    isShowPopup(false);
  };


  if (!selectedUserId)
    return (
      <>
        {showpop &&
          <Modal active={showpop} clipped>
            <Modal.Background />
            <Modal.Card>
              <Modal.Card.Body>
                <div className=' content'>
                  <p>Upgrade your business subscription to access this operation.</p>
                </div>
                <div className='is-flex is-justify-content-space-evenly'>
                  <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                  <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
                </div>
              </Modal.Card.Body>
            </Modal.Card>
          </Modal>
        }
        <div className='hero is-fullheight-with-navbar'>
          <div className='hero-body has-text-centered is-flex-direction-column is-justify-content-center py-5'>
            <span>Choose user to view conversation</span>
            <span>or</span>
            <Link
              to='#'
              className='has-text-info'
              onClick={handleOpenNewMessageModal}
            >
              Start new conversation
            </Link>
          </div>
        </div>
      </>
    );

  // if (isLoading || isDataLoading) return <Loader isFullScreen />;

  return (
    <HeroSection>
      <TopBar>
        <article className='media is-flex is-justify-content-center is-align-items-center'>
          <Icon
            name='arrow-left'
            className='mr-2 is-hidden-tablet'
            onClick={handleBack}
          />
          <figure className='media-left'>
            <p className='image is-32x32 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
              {String(userName || email)
                .toUpperCase()
                .charAt(0)}
            </p>
          </figure>
          <div className='media-content'>
            <StyledContent>
              <div>{userName || email || phoneNumber}</div>
              <span className='is-size-7 has-text-grey'>
                {userName && phoneNumber
                  ? email + ` - ${phoneNumber}`
                  : userName
                    ? email
                    : phoneNumber}
              </span>
            </StyledContent>
          </div>
        </article>
        <div className='dropdown is-hoverable is-right ml-3'>
          <div className='dropdown-trigger'>
            <Button
              className='icon-button action-btn'
              aria-haspopup='true'
              aria-controls='actions-dropdown'
            >
              <Icon name='ellipsis-v' color='info' scale='lg' />
            </Button>
          </div>
          <div className='dropdown-menu' id='actions-dropdown' role='menu'>
            <div className='dropdown-content'>
              <div
                className='dropdown-item'
                onClick={() => setOpenAttachmentsModal(true)}
              >
                <Icon name='paperclip' color='success' />
                Attachments
              </div>
            </div>
          </div>
        </div>
      </TopBar>
      <ScrollList id='scroll-list'>
        {isEmpty(userConversation) ? (
          <div>
            <div>
              <Loader isFullScreen />
            </div>
          </div>
        ) : (
          <>
            {userConversation?.map((conversation) => {
              const {
                user: { id },
                messageSource,
                messageType,
                message: {
                  user: { id: userId, fullName },
                  commentText,
                  createdTime,
                },
                attachment,
              } = conversation;

              return (
                <Fragment>
                  {isEqual(userId, id) ? (
                    <ListItem>
                      <div className='is-flex is-flex-direction-column is-align-items-flex-start'>
                        <MessageContent
                          role='RECEIVER'
                          messageContent={commentText}
                          messageType={messageType}
                          attachment={attachment}
                        />
                        <small className='is-size-7 has-text-grey mt-1'>
                          {moment.utc(createdTime).local().calendar()}{' '}
                          {
                            messageSource === "Default" ? <LogoStyled src={logo} /> :
                              messageSource === "WhatsApp" ? <IconStyled name='whatsapp' /> :
                                messageSource === "Sms" ? <IconStyled name='commenting-o' /> :
                                  <IconStyled name='envelope-o' />
                          }

                        </small>
                      </div>
                    </ListItem>
                  ) : (
                    <ListItem sender={true}>
                      <div className='is-flex is-flex-direction-column is-align-items-flex-end'>
                        <MessageContent
                          role='SENDER'
                          messageContent={commentText}
                          messageType={messageType}
                          attachment={attachment}
                        />
                        <small className='is-size-7 has-text-grey mt-1'>
                          {
                            messageSource === "Default" ? <LogoStyled src={logo} /> :
                              messageSource === "WhatsApp" ? <IconStyled name='whatsapp' /> :
                                messageSource === "Sms" ? <IconStyled name='commenting-o' /> :
                                  <IconStyled name='envelope-o' />
                          }
                          {' '} {fullName} | {' '}
                          {moment.utc(createdTime).local().calendar()} {' '}
                        </small>
                      </div>
                    </ListItem>
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      </ScrollList>
      {isOpenAttachmentDropZone && (<AttachmentDropZoneBox isLoading={onSendMessageLoading}   {...fileParams} onUpload={onUpload} />)}
      {isOpenPromotionDropZone && (<PromotionDropZoneBox businessCoupons={businessCoupons} onCouponSend={handleCouponSend} />)}
      {isOpenFormDropZone && (<FormsDropZone businessGoogleForms={businessGoogleForms} onFormSend={handleFormSend} />)}
      <SendInputSection>
        {
          messagingAllowed ? (
            genericNumberMessagingAllowed ? (
              <>
                <div className={`dropdown is-up ${isOpenEmojiModal ? 'is-active' : ''}`}   >
                  <div className='dropdown-trigger'>
                    <Button className='button-icon is-small mr-3' aria-haspopup='true' aria-controls='emoji-dropdown' onClick={handleEmojiModalOpen}  >
                      <Icon name='smile-o' style={{ fontSize: 18 }}></Icon>
                    </Button>
                  </div>
                  <div className='dropdown-menu p-0' id='emoji-dropdown' role='menu'>
                    <div className='dropdown-content p-0'>
                      <div className='dropdown-item p-0'>
                        <Button className='button-icon is-small is-right' onClick={handleEmojiModalOpen}   >
                          <Icon name='times' style={{ fontSize: 18 }}></Icon>
                        </Button>
                        <EmojiPicker onEmojiClick={handleEmojiChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <MessageField value={messageText} onKeyDown={handleKeyEnter} onChange={handleMessageTextChange} placeholder='Type a message here' />
                {onSendMessageLoading ? (<ClipLoader size='20' />) : (<SendButton name='paper-plane' className='text-left' onClick={handleMessageSend} />)}
                <PromotionButton className='ml-3' data-hover-text='Form'>
                  {isOpenFormDropZone ? (<Icon name='times' onClick={onRemoveFormsDropZone} />) : (<Icon name='file' onClick={handleFormsDropZoneOpen} />)}
                </PromotionButton>

                {
                  businessCategoryGlobal?.features?.couponAllowed
                  &&
                  <PromotionButton className='ml-3' data-hover-text='Coupons' onClick={handlePromotionDropZoneOpen}>
                    {isOpenPromotionDropZone ? (<Icon name='times' onClick={onRemove} />) : (<Icon name='money' />)}
                  </PromotionButton>
                }

                <AttachmentButton className='ml-3' onClick={handleAttachmentDropZoneOpen}   >
                  {isOpenAttachmentDropZone ? (<Icon name='times' onClick={onRemove} />) : (<Icon name='paperclip' />)}
                </AttachmentButton>

              </>
            ) : (
              <Text size='6' color='danger'>  Upgrade your business subscription to access this operation.  </Text>
            )
          ) : (
            <Text size='6' color='danger'>  Your messaging feature is off.  </Text>
          )}
      </SendInputSection>
      <AttachmentsPanel userConversation={userConversation} isOpen={isOpenAttachments} onClose={() => setOpenAttachmentsModal(false)} />
    </HeroSection>
  );
};

const MessageContent = ({ attachment, messageContent, messageType, role }) => {
  const { contentType, publicUrl, fileName } = attachment || {
    contentType: null,
    publicUrl: null,
    fileName: '',
    publicUrl: '',
  };

  let contentDate = messageContent.split('-').pop();
  let title = messageContent.replace('-' + contentDate, ' ');

  if (isEqual(role, 'RECEIVER')) {
    if (messageType == 'Attachment') {
      if (String(contentType).includes('image')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' style={{ background: 'transparent' }}    >    <img src={publicUrl} width={150} height={130} /> </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('video')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-video-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('audio')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-audio-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }

      if (String(contentType).includes('application')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'  >
              <span>
                <Icon name='fa-solid fa-file-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }

      if (String(contentType).includes('pdf')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-pdf-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }

      if (String(contentType).includes('spreadsheet')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-excel-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('presentation')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-powerpoint-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('plain')) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-text-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (
        String(contentType).includes('docx') ||
        String(contentType).includes('document')
      ) {
        return (
          <RecipientBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-word-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
    } else if (messageType == 'BusinessCoupon') {
      return (
        <RecipientBadge>
          <a href={publicUrl} target='__blank' style={{ background: 'transparent' }}   >
            <Title size='6' className='m-0'>  We have a deal you might like    </Title>
            <Subtitle size='6' className='mt-1 mb-2'>   {title}     </Subtitle>
            {publicUrl ? (
              <img src={publicUrl} width={150} height={130} className='has-text-centered' />
            ) : (
              <Subtitle size='6' className='mt-1 mb-2'>
                {contentDate}
              </Subtitle>
            )}
          </a>
        </RecipientBadge>
      );
    } else {
      return (
        <RecipientBadge>
          <Linkify tagName='span' style={{ wordBreak: "break-all" }} options={{ attributes: { target: '__blank' } }} > {messageContent}  </Linkify>
        </RecipientBadge>
      );
    }
  } else {
    if (messageType == 'Attachment') {
      if (String(contentType).includes('image')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' style={{ background: 'transparent' }}   >
              <img src={publicUrl} width={150} height={130} />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('video')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-video-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('audio')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-audio-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }

      if (String(contentType).includes('application')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'  >
              <span>
                <Icon name='fa-solid fa-file-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }

      if (String(contentType).includes('pdf')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-pdf-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }

      if (String(contentType).includes('spreadsheet')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-excel-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }

      if (String(contentType).includes('presentation')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-powerpoint-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('plain')) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'    >
              <span>
                <Icon name='file-text-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (
        String(contentType).includes('docx') ||
        String(contentType).includes('document')
      ) {
        return (
          <SenderBadge>
            <a href={publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'   >
              <span>
                <Icon name='file-word-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }

      return (
        <SenderBadge>
          <a href={publicUrl} target='__blank' style={{ background: 'transparent' }}   >
            <img src={publicUrl} width={150} height={130} />
          </a>
          {messageContent && <div className='mt-1'>{messageContent}</div>}
        </SenderBadge>
      );
    } else if (messageType == 'BusinessCoupon') {
      return (
        <SenderBadge>
          <a href={publicUrl} target='__blank' style={{ background: 'transparent' }}   >
            <Title size='6' className='m-0'>    We have a deal you might like   </Title>
            <Subtitle size='6' className='mt-1 mb-2'>   {title}     </Subtitle>
            {publicUrl ? (
              <img src={publicUrl} width={150} height={130} className='has-text-centered' />
            ) : (
              <Subtitle size='6' className='mt-1 mb-2'>   {contentDate}   </Subtitle>
            )}
          </a>
        </SenderBadge>
      );
    } else {
      return (
        <SenderBadge>
          <Linkify tagName='span' style={{ wordBreak: "break-all" }} options={{ attributes: { target: '__blank' } }}   > {messageContent}  </Linkify>
        </SenderBadge>
      );
    }
  }

  return <Fragment></Fragment>;
};

const AttachmentDropZoneBox = ({ onUpload, path, type, isLoading }) => {
  return (
    <Dropzone multiple={false} onDrop={onUpload}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <StyledDropBox>
            {!isEmpty(path) ? (
              String(type).includes('image') ? (
                <StyledDropImage src={`data:${type};base64,` + path} />
              ) : String(type).includes('word') ? (
                <StyledIcon name='file-word-o' />
              ) : String(type).includes('video') ? (
                <StyledIcon name='file-video-o' />
              ) : String(type).includes('audio') ? (
                <StyledIcon name='file-audio-o' />
              ) : String(type).includes('application') ? (
                <StyledIcon name='fa-solid fa-file-o' />
              ) : String(type).includes('pdf') ? (
                <StyledIcon name='file-pdf-o' />
              ) : String(type).includes('plain') ? (
                <StyledIcon name='file-text-o' />
              ) : String(type).includes('spreadsheet') ? (
                <StyledIcon name='file-excel-o' />
              ) : String(type).includes('presentation') ? (
                <StyledIcon name='file-powerpoint-o' />
              ) : (
                <p>File not supported</p>
              )
            ) : (
              <p>Drag file here</p>
            )}
          </StyledDropBox>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};

const PromotionDropZoneBox = ({ businessCoupons, onCouponSend }) => {
  return (
    <Dropzone multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
          <Title size='6'>Select Coupon</Title>
          <hr />
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Code</th>
                <th>Title</th>
                <th>Coupon Type</th>
                <th>Value</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {businessCoupons?.map((coupon, index) => (
                <tr>
                  <td>{coupon?.code}</td>
                  <td>{coupon?.title}</td>
                  <td>{coupon?.discountType}</td>
                  <td>{coupon?.discount}</td>
                  <td>
                    <Button size='small' color='info'
                      onClick={() =>
                        onCouponSend({
                          id: coupon.id,
                          title: coupon.title,
                          summary: coupon.summary,
                          image: coupon.couponUrl || coupon.imageUrl,
                        })
                      }
                    >
                      Send
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};
