import React, { Fragment, useEffect } from 'react'
import { Field, Control, Button, Label, Icon, Columns, Column } from '@safelyq/bulma-ui-library';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';

export const TargetAudience = ({ isZipCode, handleZipCodeAdd, handleRemoveZipCode,
    isFile, zipCodeList, campaign, setCampaign, currentRemainingBonus, isCalculateLoading, isAudienceButtonEnable,
    audienceButtonDisable, handleCalculateValidation, costInDollar, businessFiles, campaignTargetTimes }) => {

    let { businessId } = useParams();
    businessId = parseInt(businessId);
    const [isEmptyNotZipCode, setIsEmptyNotZipCode] = useState();

    useEffect(() => {
        setIsEmptyNotZipCode(zipCodeList?.length > 0);
    }, [zipCodeList]);

    const handleCampaignChange = ({ target: { name, value } }) => {
        if (name === "budget") {
            value >= 0 && setCampaign({ ...campaign, [name]: parseFloat(value) });
        }
        else {
            setCampaign({ ...campaign, [name]: value });
        }
    };

    return (
        <Fragment>
            <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
                <div className='box pr-6'>
                    <Column size='12'>
                        <Label className='has-text-info'>
                            Target Audience <span className='ml-1 has-text-danger'>*</span>
                        </Label>
                    </Column>
                    <Columns multiline className='mt-1'>
                        <Column size='1' className='is-flex-desktop is-justify-content-center is-align-items-center'>
                            <Label className='radio'>
                                <LargeRadio
                                    id='file'
                                    name='radioButton1'
                                    type='radio'
                                    value='file'
                                    checked={isZipCode}
                                    onChange={() => {
                                        setCampaign({
                                            ...campaign,
                                            isZipCode: true,
                                            isFile: false
                                        })
                                    }
                                    }
                                // defaultChecked={existingCampaign && details || !existingBusinessCoupon}
                                // onChange={handleRadioButtonChange}
                                />
                            </Label>
                        </Column>
                        <Column size='4'>
                            <Field>
                                <Label className='has-text-info'>
                                    ZipCode {`(${zipCodeList.length}/5)`} <span className='has-text-danger'>*</span>
                                </Label>
                                <Control>
                                    <input
                                        // style={{width:"200px"}}
                                        name='zipCode'
                                        type='number'
                                        className='input'
                                        disabled={zipCodeList.length >= 5 || isFile}
                                        value={campaign.zipCode}
                                        onChange={handleCampaignChange}
                                        onKeyPress={handleZipCodeAdd}
                                    />
                                    <small>
                                        <i>Enter ZIP Code and press enter</i>
                                    </small>
                                    {isEmptyNotZipCode && (
                                        <div className='field is-grouped is-grouped-multiline mt-2'>
                                            {zipCodeList?.map((name, index) => (
                                                <div
                                                    className='control has-text-grey'
                                                    key={`zipCode-${index}`}
                                                >
                                                    <div className='tags has-addons'>
                                                        <span className='tag '>{name}</span>
                                                        <a
                                                            className='tag is-delete'
                                                            onClick={() =>
                                                                handleRemoveZipCode({
                                                                    index,
                                                                })
                                                            }
                                                        ></a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Control>
                            </Field>
                        </Column>
                        <Column size='2'>
                            <Field>
                                <Label className='has-text-info'>
                                    Budget <span className='has-text-danger'>*</span>
                                </Label>
                                <div className='control has-icons-left'>
                                    <input
                                        style={{ width: "200px" }}
                                        name='budget'
                                        type='number'
                                        className='input'
                                        value={isZipCode ? campaign.budget : 0.00}
                                        disabled={isFile}
                                        onChange={handleCampaignChange}
                                    />
                                    <span className='icon is-small is-left'>
                                        <Icon style={{ color: "#666" }} name='usd' />
                                    </span>
                                </div>
                            </Field>
                        </Column>
                        <Column size='3'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>Current Remaining Bonus</Label>
                                    <input
                                        style={{ width: "200px" }}
                                        className='input'
                                        name='currentRemainingBonus'
                                        disabled={true}
                                        value={`$ ${parseFloat(currentRemainingBonus).toFixed(2)}`}
                                    />
                                </Control>
                            </Field>
                        </Column>
                        <Column size='2' className='px-0'>
                            <Field>
                                <Control>
                                    <Label>&nbsp;</Label>
                                    <Button
                                        className='is-fullwidth'
                                        color='info'
                                        // isFile: if the file exists or osZipCode: custom uploading zipcodes
                                        disabled={!isZipCode || isFile || isCalculateLoading || !isAudienceButtonEnable() || audienceButtonDisable}
                                        onClick={handleCalculateValidation}
                                    >
                                        {isCalculateLoading && isZipCode ? <span className='pt-1' style={{ width: "133.5px" }}><ClipLoader color="white" size='20' /></span> : "Calculate Audience"}

                                    </Button>
                                </Control>
                            </Field>
                        </Column>
                    </Columns>
                    <hr />
                    <Columns multiline>
                        <Column size='1' className='is-flex-desktop is-justify-content-center is-align-items-center'>
                            <Label className='radio'>
                                <LargeRadio
                                    id='file'
                                    name='radioButton1'
                                    type='radio'
                                    value='file'
                                    checked={isFile}
                                    onChange={() => {
                                        setCampaign({
                                            ...campaign,
                                            isZipCode: false,
                                            isFile: true
                                        })
                                        // setIsZipCode(false)
                                        // setIsFile(true)
                                    }
                                    }
                                />
                            </Label>
                        </Column>
                        <Column size='4'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>Custom Files
                                        <Link className='is-pulled-right' to={`/admin/manage-files/${businessId}`}>
                                            <Tooltip title="Manage Files" color="#3298dc">
                                                <Icon name='external-link' className='mr-3' />
                                            </Tooltip>
                                        </Link>

                                    </Label>
                                    <div className='select is-fullwidth'>
                                        <select
                                            name='attachmentId'
                                            value={campaign.attachmentId}
                                            disabled={isZipCode}
                                            onChange={handleCampaignChange}
                                        >
                                            <option value={0}>Select File</option>
                                            {businessFiles.map(({ id, fileName }) => (
                                                <option key={id} value={id}>
                                                    {fileName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Control>
                            </Field>
                        </Column>
                        <Column size='2'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>Cost</Label>
                                    <input
                                        style={{ width: "200px" }}
                                        className='input'
                                        name='costInDollar'
                                        disabled={true}
                                        value={`$ ${parseFloat(costInDollar).toFixed(2)}`}
                                    />
                                </Control>
                            </Field>
                        </Column>
                        <Column size='3'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>Current Remaining Bonus</Label>
                                    <input
                                        style={{ width: "200px" }}
                                        className='input'
                                        name='currentRemainingBonus'
                                        disabled={true}
                                        value={`$ ${parseFloat(currentRemainingBonus).toFixed(2)}`}
                                    />
                                </Control>
                            </Field>
                        </Column>
                        <Column size='2' className='px-0'>
                            <Field>
                                <Control>
                                    <Label>&nbsp;</Label>
                                    <Button
                                        className='is-fullwidth'
                                        color='info'
                                        disabled={
                                            (campaign?.attachmentId === "0" || campaign?.attachmentId === null) || // checks if attachmentId exists of the csv file
                                            !isFile || // checks if the file is selected via radio button
                                            isZipCode || // checks if zipCode is not selected
                                            isCalculateLoading ||
                                            !isAudienceButtonEnable() ||
                                            audienceButtonDisable}
                                        // disabled={!campaign?.attachmentId && !(!isFile || isZipCode || isCalculateLoading || !isAudienceButtonEnable() || audienceButtonDisable)}
                                        onClick={handleCalculateValidation}
                                    >
                                        {isCalculateLoading && isFile ? <span className='pt-1' style={{ width: "133.5px" }}><ClipLoader color="white" size='20' /></span> : "Calculate Cost"}

                                    </Button>
                                </Control>
                            </Field>
                        </Column>
                    </Columns>
                </div>
            </Column>
        </Fragment>
    )
}

const LargeRadio = styled.input`
  height: 18px;
  width: 18px;
`;