import React from 'react';
import moment from 'moment';
import { Modal, Title, Text, Buttons, Button } from '@safelyq/bulma-ui-library';
import { Fragment } from 'react';

export const PromotionModal = ({ ...props }) => {
    const { isOpen, onClose, data, setState } = props;
    const { businessCoupons } = data;

    const filterData = businessCoupons.filter(businessCoupon => {
        return businessCoupon.expireStatus === false;
    })

    const handleSelectButton = (coupon) => {
        setState(coupon)
        onClose()
    }

    return (
        <Fragment>
            <Modal active={isOpen} clipped>
                <Modal.Background />
                <Modal.Card style={{ borderRadius: '8px' }}>
                    <Modal.Card.Body>
                        <Text>
                            <Title size='6'>Select Coupon</Title>
                        </Text>
                        <table className='table is-fullwidth'>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Summary</th>
                                    <th>Expire Date</th>
                                    <th>Discount</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterData?.map((coupon) => (
                                    <tr>
                                        <td>{coupon?.title}</td>
                                        <td>{coupon?.summary}</td>
                                        <td>{moment(coupon?.endDate).format('MM-DD-YYYY')}</td>
                                        <td>{coupon?.discount}</td>
                                        <td>
                                            <Button
                                                style={{ fontSize: '10px' }}
                                                className='is-rounded'
                                                size='small'
                                                color='info'
                                                onClick={() => handleSelectButton(coupon)}
                                            >
                                                Select
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='mt-5'>
                            <Buttons size='small' className='is-right'>
                                <Button onClick={onClose}>Cancel</Button>
                            </Buttons>
                        </div>
                    </Modal.Card.Body>
                </Modal.Card>
            </Modal>
        </Fragment>

    );
};