import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Columns, Column, Button, } from '@safelyq/bulma-ui-library';
import { useData } from './hooks';
import { ImagesComponent } from './components';
import { useSelector } from 'react-redux';

export const BusinessImagesSetup = ({ data, refetch, onChangeStep, toolTip, gotToNextStep }) => {
  const { isLoading, isDeleteLoading, onSaveImages, onBusinessImageDelete } = useData(refetch);
  const { id } = useParams();
  const [uploadImageLoading, setUploadImageLoading] = useState({
    sectionName: null,
    isLoading: false
  });

  const [initialImages, setInitialImages] = useState();
  const [disableSave, setDisableSave] = useState(true);
  const [canCompare, setCanCompare] = useState(false)

  const [allImages, setAllImages] = useState({
    business: [],
    menu: [],
    logo: []
  })
  const [locallyUploadedImages, setLocallyUploadedImages] = useState({ business: [], menu: [] });

  const stateBusinessId = useSelector(state => state?.business?.businessId);
  const businessId = id || stateBusinessId;
  // const businessId = id || localStorage.getItem('businessId');

  useEffect(() => {
    // If can compare is false means its first render and nothing changed, fill initial with images
    if (canCompare === false) {
      setInitialImages(allImages)
    }
    else {
      // Compare Intial Images and All Images
      compareImages();
    }
  }, [allImages])

  const compareImages = () => {
    if (allImages?.business.length === 0 && allImages?.menu.length === 0 && (allImages?.logo.length === 0 || allImages?.logo[0].path === null)) {
      return;
    }

    const { business, logo, menu } = allImages;
    const { business: businessInitial, logo: logoInitial, menu: menuInitial } = initialImages;

    const areObjectsEqual =
      JSON.stringify(business) === JSON.stringify(businessInitial) &&
      JSON.stringify(logo) === JSON.stringify(logoInitial) &&
      JSON.stringify(menu) === JSON.stringify(menuInitial);

    if (areObjectsEqual) {

      setDisableSave(true);
    } else {

      setDisableSave(false);
    }
  }

  const handleUploadImage = (newImage, section) => {
    setAllImages((prevallimg) => ({
      ...prevallimg,
      [section]: [...prevallimg[section], newImage]

    }))

    // Now can compare because data changed
    setCanCompare(true);
    // Image upload loading false
    setUploadImageLoading({ ...uploadImageLoading, isLoading: false })

    // Set in state the locally uploaded Images
    const newSection = section === "menus" ? "menu" : section;
    setLocallyUploadedImages((prevImages) => ({
      ...prevImages,
      [newSection]: [...(prevImages[newSection] || []), newImage],
    }));
  }

  const handleDeleteImage = (deleteImage, section) => {
    if (deleteImage.base64String) {
      const updatedImages = allImages[section].filter((sec) => sec.fileName !== deleteImage.fileName)
      setAllImages((prevallimg) => ({
        ...prevallimg,
        [section]: updatedImages
      }))

      // If Picture removed without saving then also remove from locallyUploaded State
      const newSection = section === "menus" ? "menu" : section;
      const update = locallyUploadedImages[newSection].filter((sec) => sec.fileName !== deleteImage.fileName)
      setLocallyUploadedImages((prevImages) => ({
        ...prevImages,
        [newSection]: update,
      }));

    } else {
      handleBusinessPictureDelete(deleteImage.id, section)
    }
  }

  const handleUploadLogoImage = (newImage, section) => {
    setAllImages((prevallimg) => ({
      ...prevallimg,
      [section]: [newImage]
    }))

    // Now can compare because data changed
    setCanCompare(true)
    // Image upload loading false
    setUploadImageLoading({ ...uploadImageLoading, isLoading: false })
  }

  const handleDeleteLogoImage = (deleteImage, section) => {
    if (deleteImage.base64String) {
      setAllImages((prevallimg) => ({
        ...prevallimg,
        logo: [{ path: '', base64String: '' }]
      }))
    } else {
      handleBusinessPictureDelete(deleteImage.id, section)
    }
  }

  const handleBusinessPictureDelete = (pictureId, section) => {
    if (section === 'business') {
      if (!window.confirm('Are you sure that you want to delete this business Picuter ?')) return;
      onBusinessImageDelete({ variables: { businessId, pictureId, businessImageType: 'PICTURE', }, });
    } else if (section === 'menu') {
      if (!window.confirm('Are you sure that you want to delete this menu Picuter ?')) return;
      onBusinessImageDelete({ variables: { businessId, pictureId, businessImageType: 'MENU', }, });
    } else {
      if (!window.confirm('Are you sure that you want to delete this logo Picuter ?')) return;
      onBusinessImageDelete({ variables: { businessId, pictureId, businessImageType: 'LOGO', }, });
    }
  };

  const handleSave = async () => {
    const imagesObj = {};
    imagesObj['images'] = allImages.business.filter((bimg) => bimg.base64String);
    imagesObj['menus'] = allImages.menu.filter((mimg) => mimg.base64String);
    if (allImages.logo[0]?.base64String) {
      imagesObj['logo'] = allImages.logo[0]
    }
    try {
      await onSaveImages({
        variables: {
          businessImagesInput: {
            businessId,
            ...imagesObj,
          },
        },
      })
      gotToNextStep([3]);
      onChangeStep(3);

    } catch (error) {

    }

    // set all images to initial images and disable save button
    setInitialImages(allImages)
    setDisableSave(true);
    setCanCompare(false)
    setLocallyUploadedImages({ business: [], menu: [] });
  }

  useEffect(() => {

    if (data.hasOwnProperty('pictures') || data.hasOwnProperty('menus') || data.hasOwnProperty('logoUrl')) {
      // Keep the locally updated and deleted images after the api call
      setAllImages({
        business: locallyUploadedImages?.business?.length !== 0 ? data?.pictures?.concat(locallyUploadedImages?.business) : data?.pictures,
        menu: locallyUploadedImages?.menu?.length !== 0 ? data?.menus?.concat(locallyUploadedImages?.menu) : data?.menus,
        logo: data?.logoUrl ? [{ path: data.logoUrl, base64String: '', id: 0 }] : [],
      })

      // setLocallyUploadedImages({ business: [], menu: [] });
    }
  }, [data.pictures, data.menus, data.logoUrl])

  return (
    <Columns multiline>
      <Column size='12'>
        <ImagesComponent
          title='Upload Business Pictures'
          images={allImages.business}
          sectionName='business'
          onUploadImage={handleUploadImage}
          onDeleteImage={handleDeleteImage}
          toolTipTitle={toolTip?.uploadBusinessPictures}
          setUploadImageLoading={setUploadImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      </Column>
      <Column size='12'>
        <ImagesComponent
          title='Upload Menu Pictures'
          images={allImages.menu}
          sectionName='menu'
          onUploadImage={handleUploadImage}
          onDeleteImage={handleDeleteImage}
          toolTipTitle={toolTip?.uploadMenuPictures}
          setUploadImageLoading={setUploadImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      </Column>
      <Column size='12'>
        <ImagesComponent
          title='Upload Logo Pictures'
          subtitle='Please upload 40px X 40px pic for better result'
          images={allImages.logo}
          sectionName='logo'
          onUploadImage={handleUploadLogoImage}
          onDeleteImage={handleDeleteLogoImage}
          toolTipTitle={toolTip?.uploadLogoPictures}
          setUploadImageLoading={setUploadImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      </Column>
      <Column size='12'>
        <Button disabled={disableSave} color='info' loading={isLoading || isDeleteLoading} onClick={handleSave}>Save</Button>
      </Column>
    </Columns>
  );
};
